import React from 'react';
import ButtonGroup from 'antd/lib/button/button-group';
import moment from 'moment';
import PropTypes from 'prop-types';
import Table from './table';
import { Box } from '@material-ui/core';
import { Button, DatePicker, Dropdown, Icon, Input, Typography, Menu } from 'antd';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import multiwalletServices from '../../../../multiwalletServices';
import { history } from '../../../../../../store/history';
import { segmentStroke } from '../../../../../constants/constants';
import ClearFilter from '../../../../../AdminComponents/filter_btns/clear_filter_btn';

const TranxHistoryTable = (props) => {
    const [selected, setSelected] = React.useState('Transfer Money')
    const [dateRange, setDateRange] = React.useState({ startDate: '', endDate: '' });
    const [visibleClearBtn, setVisibleClearBtn] = React.useState(false);
    const [searchVal, setSearchVal] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [createBtnStyle, setCreateBtnStyle] = React.useState({ color: '#1DD28B', backgroundColor: 'transparent' });
    const [tableData, setTableData] = React.useState(null);
    const [customRange, setCustomRange] = React.useState('All Time');
    const [statusFilter, setStatusFilter] = React.useState('');
    const [pageSize, setPageSize] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const { segments, setFilters } = props;
    const testMode = (history.location.pathname.indexOf('/test') > -1);

    const ButtonTab = ({ rootStyle }) => {

        const status = ['Transfer Money', 'Receive Money', 'Other Fees'];

        const changeSelectedTab = (e) => {
            setSelected(e.target.value);
        }

        return (
            <div style={styles.btnTabContainer}>
                <ButtonGroup>
                    {
                        status.map((btn, i) => {
                            return (
                                <Button
                                    key={btn}
                                    value={btn}
                                    onClick={changeSelectedTab}
                                    style={{
                                        ...styles.btnGroup,
                                        color: selected === btn ? segmentStroke[0] : '#909196',
                                        backgroundColor: '#FFF',
                                        borderBottom: selected === btn ? `2px solid ${segmentStroke[0]}` : 'transparent'
                                    }}
                                    hidden={i===2}
                                >
                                    {btn}
                                </Button>
                            )
                        })
                    }
                </ButtonGroup>
            </div>
        )
    }

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`nav-tabpanel-${index}`}
                aria-labelledby={`nav-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box style={{ padding: 0 }} p={2}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    const handleDayChange = ({ item }) => {
        setCustomRange(item.props.value)
        setDateRange({ startDate: '', endDate: '' });
    }

    const handleStatusChange = ({ item }) => {
        setStatusFilter(item.props.value)
    }

    const returnCustomRange = (key) => {
        switch (key) {
            case 'All':
                return 'All Time';
            case 'Today':
                return 'Today';
            case 'Week':
                return 'Last 7 days';
            case 'Month':
                return 'Last 30 days';
            case 'Custom':
                return 'Custom';
            default:
                return 'All Time';
        }
    }

    const dayMenu = (
        <Menu onClick={handleDayChange}>
            <Menu.Item value="All">All Time</Menu.Item>
            <Menu.Item value="Today">Today</Menu.Item>
            <Menu.Item value="Week" >Last 7 days</Menu.Item>
            <Menu.Item value="Month" >Last 30 days</Menu.Item>
        </Menu>
    )

    const statusMenu = (
        <Menu onClick={handleStatusChange}>
            <Menu.Item key="success" value={'success'}>
                Success
            </Menu.Item>
            <Menu.Item key="failed" value={'failed'}>
                Failed
            </Menu.Item>
        </Menu>
    )

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');  
    }

    const rangeSelect = (dates, dateString, id) => {
        setDateRange({ ...dateRange, [id]: moment(dateString).format('MM-DD-YYYY') });
        setCustomRange('Custom');
    }

    const onFilterApply = () => {
        setPage(1);
        getTranxHistory(testMode, returnSelected(selected), searchVal, dateRange.startDate, dateRange.endDate, statusFilter, customRange, pageSize, 1);
        setVisibleClearBtn(true);
        setFilters([returnSelected(selected), searchVal, dateRange.startDate, dateRange.endDate, statusFilter, customRange, pageSize, 1]);
    }

    const handleSearch = (e) => {
        setSearchVal(e.target.value);
    }


    const resetFilters = () => {
        setDateRange({ startDate: '', endDate: '' });
        setSearchVal('');
        setStatusFilter('')
        setVisibleClearBtn(false);
        getTranxHistory(testMode, returnSelected(selected));
        setFilters([]);
        setPage(1);
        setCustomRange('All');
    }

    const applyBtnDisabled = () => {
        return !dateRange.startDate &&
        !dateRange.endDate &&
        statusFilter === 'Status' &&
        customRange === 'All' &&
        !searchVal
    }

    const onCreateBtnHover = () => {
        setCreateBtnStyle({
            color: '#FFF',
            backgroundColor: '#1DD28B'
        });
    }

    const onCreateBtnLeave = () => {
        setCreateBtnStyle({
            color: '#1DD28B',
            backgroundColor: 'transparent'
        });
    }

    const getTranxHistory = async (isTestMode, type, search, start, end, status, summary, pageSize, page) => {
        setIsLoading(true);
        try {
            const res = await multiwalletServices.getTransferHistory(isTestMode, type, search, start, end, status, summary, pageSize, page);
            if (res) {
                setTableData(res);
            }
        } catch (error) {
            console.error(error)
        }
        setIsLoading(false);
    }

    const changePage = (page) => {
        getTranxHistory(testMode, returnSelected(selected), searchVal, dateRange.startDate, dateRange.endDate, statusFilter, customRange, pageSize, page);
        setPage(page);
    }

    const returnSelected = (selected) => {
        switch (selected) {
            case 'Transfer Money':
                return 'sent';
            case 'Receive Money':
                return 'received';
            case 'Other Fees':
                return 'sent';
            default:
                return 'all';
        }
    }

    React.useEffect(() => {
        setFilters([returnSelected(selected), searchVal, dateRange.startDate, dateRange.endDate, statusFilter, customRange, pageSize, page]);
        getTranxHistory(testMode, returnSelected(selected), searchVal, dateRange.startDate, dateRange.endDate, null, customRange);
    }, [selected])

    return (
        <div style={styles.root}>
            <div style={styles.btnContainer}>
                <ButtonTab />
            </div>
            <div style={styles.body}>
                <div style={styles.subContainer} className="top-20">
                    <ClearFilter hideFilterBtn={!visibleClearBtn} clearFilter={resetFilters} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '0 0 24px 0' }}>
                    <Dropdown overlay={dayMenu} trigger={['click']}>
                        <a style={styles.dropdownItems} >
                            {returnCustomRange(customRange)}
                            <ExpandMoreIcon style={styles.dropdownArror} />
                        </a>
                    </Dropdown>
                    <DatePicker
                        format='MM/DD/YYYY'
                        disabledDate={disabledDate}
                        // defaultValue={moment()}
                        value={dateRange.startDate && moment(dateRange.startDate)}
                        size="large" picker="month"
                        onChange={(dates, dateString) => rangeSelect(dates, dateString, 'startDate')}
                        placeholder='Start date'
                        style={styles.datePic}
                        suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                        allowClear={false} />
                    <span style={{ paddingTop: 8 }}>-</span>
                    <DatePicker
                        format='MM/DD/YYYY'
                        disabledDate={disabledDate}
                        // defaultValue={moment()}
                        value={dateRange.endDate && moment(dateRange.endDate)}
                        size="large"
                        picker="month"
                        onChange={(dates, dateString) => rangeSelect(dates, dateString, 'endDate')}
                        placeholder='End date'
                        style={styles.datePic}
                        suffixIcon={<div style={styles.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                        allowClear={false} />
                    <Dropdown overlay={statusMenu} trigger={['click']}>
                        <a style={styles.dropdownItems} >
                            {statusFilter === '' ? 'Status' : statusFilter.charAt(0).toUpperCase() + statusFilter.slice(1)}
                            <ExpandMoreIcon style={styles.dropdownArror} />
                        </a>
                    </Dropdown>
                    <Input
                        placeholder="Search"
                        style={{ width: '200px', height: '40px', borderRadius: '4px', padding: '0 8px 0 8px' }}
                        suffix={<Icon type="search" style={{ fontSize: '18px' }} />}
                        onChange={handleSearch}
                        value={searchVal}
                    />
                    <button className={`btn--${segments} btn-height`} style={{opacity: applyBtnDisabled()? '0.5' : '1'}} disabled={applyBtnDisabled()} onClick={onFilterApply} >Apply</button>
                </div>
                <Table
                    data={tableData}
                    isLoading={isLoading}
                    pageSize={pageSize}
                    selected={selected}
                    onChangePage={changePage}
                    selected={selected}
                />
            </div>
        </div>
    )
}

const styles = {
    root: {
        width: '100%',
        padding: '40px 0'
        //   display: 'flex', 
        //   flexDirection: 'column', 
        // //   justifyContent: 'center', 
        //   alignItems: 'center'
    },
    body: {
        padding: '0px 16px',
        backgroundColor: '#FFF',
        // border: '1px solid #E6EAF0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        // margin: '38px 0'
    },
    btnContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #D1D5DD',
        padding: '0 16px'
    },
    exportBtn: {
        fontSize: 16,
        fontWeight: '600',
        padding: '8px, 16px, 8px, 16px',
        border: '1px solid #1DD28B',
        height: 40,
        marginRight: 12,
        color: '#FFF',
        backgroundColor: '#1DD28B'
    },
    btnGroup: {
        fontSize: 14,
        fontWeight: '500',
        textAlign: 'center',
        height: 40,
        padding: '10px 16px 36px 16px',
        borderColor: 'transparent'
    },
    btnTabContainer: {
        backgroundColor: '#FFF',
        width: '100%',
        padding: '20px, 20px 0 20px',
    },
    subContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 0'
    },
    clearDiv: {
        display: 'flex',
        alignItems: 'center',
    },
    clearIcon: {
        color: '#2B2D33',
        fontSize: 10,
    },
    filterText: {
        color: '#000',
        fontSize: 14,
        fontWeight: 'bold'
    },
    clearBtnDiv: {
        backgroundColor: '#E6EAF0',
        borderRadius: 24,
        padding: '4px 12px',
        margin: '0 12px'
    },
    clearBtn: {
        color: '#000000',
        fontSize: 12,
        paddingLeft: 4,
    },
    reminder: {
        fontSize: 12,
        color: '#909196',
    },
    datePic: {
        height: 40,
        fontSize: 14,
        color: '#2F3542',
        width: 178,
        padding: '0 8px 0 8px'
    },
    suffixIcon: {
        marginTop: '-12px',
        marginRight: 12,
        color: '#000'
    },
    applyBtn: {
        height: 40,
        width: 92,
        fontSize: 16,
        fontWeight: '600',
        borderRadius: 4,
        backgroundColor: '#F5922F',
        color: '#FFF',
    },
    createBtn: {
        fontSize: 16,
        fontWeight: '600',
        height: 40,
        borderColor: '#1DD28B',
        marginRight: 8,
    },
    dropdownItems: {
        display: 'flex',
        alignItems: 'center',
        padding: '7px 4px 7px 16px',
        fontSize: 16,
        color: '#2B2D33',
        border: '1px solid #D1D5DD',
        borderRadius: 5,
    },
    dropdownArror: {
        color: '#2B2D33',
        fontSize: 24,
        margin: '0 4px'
    },
}

export default TranxHistoryTable;