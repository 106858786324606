import React from 'react'
import {Card, Select, Input, Alert} from 'antd'
import enterpriseStyle from '../kybStyle'
import { isMobile } from 'react-device-detect'
import {BANKS} from '../../../constants/constants'
import NoteCard from '../noteCard'
import TextInput from '../custom_component/textInput'
import { backToTop } from '../../../constants/constants'

const {Option} = Select

const BankAccount = props => {
    const kybStep = localStorage.getItem('KYBStep')
    let data = props.datas
    const kyb = enterpriseStyle()
    const banks = BANKS
    banks.sort()

    let bank_option = banks.length > 0 && banks.map((item, i) => {
      
      return (
        <Option key={i} value={item}>{item}</Option>
      )
    }, this)

    let at = [
        {code: 'SA', name: 'Savings'},
        {code: 'CU', name: 'Current'},
        {code: 'DI', name: 'Digital'},
        {code: 'CH', name: 'Checking'}
    ]

    let accountType = at.length > 0 && at.map((item, i) => {
        return(
            <Option key={i} value={item.code}>{item.name}</Option>
        )
    })

    const note = "Bank Account details should have the same information as found on your MOA."  

    const [bank, setBank] = React.useState(!data || data.bank == "UnionBank of the Philippines" ? "UnionBank of the Philippines" : data.bank)
    const [branch, setBankBranch] = React.useState(!data || data.branch == "" ? "" : data.branch)
    const [account_type, setAccountType] = React.useState(!data || data.account_type == "" ? "" : data.account_type)
    const [account_name, setAcctName] = React.useState(!data  || data.account_name == "" ? "" : data.account_name)
    const [account_number, setAcctNum] = React.useState(!data || data.account_number == "" ? "" : data.account_number)
    const [inputValue, setInputValue] = React.useState("")
    //alert message
    const [showError, setError] = React.useState(true)
    const [errorMessage, setErrorMessage] = React.useState("")

    function setBanks(value){
        console.log(value)
        setBank(value)
    }

    function setType(val){
        setAccountType(val)
    }


    function errorBox(errorMsg){
        backToTop()
        setError(false);
        setErrorMessage(errorMsg)
        setTimeout(() => {
            setError(true);
        }, 6000);
        return
    }

    function submitStep6(){
        let format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if(!bank){
            errorBox("Please Select Bank")
        }
        else if(!branch){
            errorBox("Branch is required")
        }
        else if(!account_name){
            errorBox("Account Name is required")
        }
        else if(!account_number){
            errorBox("Account Number is required")
        }
        else if(!account_type){
            errorBox("Please select account type")
        }

        else if(format.test(account_name)){
            errorBox("Account name should not contain any special characters")
        }

        else {
            let bankInfo = {
                account_name,
                account_number,
                account_type,
                bank,
                branch,
            }
    
            let params = {
                bank_account: bankInfo,
                step_to_update: 6
    
            }
    
            props.submitStep(params, 6)
        }



    }

    function editBusinessInfo(val){
        props.openEdit(val)
    }

    return(
        <div>
        <Card 
        className={kyb.formCardStyle}
        bodyStyle={{padding: '20px 16px 20px 16px'}}>
            <div className="bottom-32 twoCol">
                <div className={kyb.headerStyle}style={{ fontSize: isMobile ? '16px' : '20px'}}>Bank Account</div>
                <div>
                    <a href className={`text-${props.segments} medium-font-weight`} onClick={() => editBusinessInfo(6)} hidden={kybStep < 6 || localStorage.getItem("KYBType") == "creation" && data ? data.step < 7 : ''}>Cancel</a>
                </div>
            </div>
            <NoteCard note={note} />

            {props.alertMessage}
            <div hidden={showError} style={{marginTop: 20}}>
                <Alert type="error" message={errorMessage} style={{borderLeft: '4px solid red'}}/>
            </div>

                <div className={kyb.displayResponsive}>
                    <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div className={kyb.miniText}>Bank Name</div>
                        <Select
                            data-test="input-bank"
                            showSearch
                            defaultValue={bank}
                            value={bank}
                            onChange={(ev)=>setBanks(ev)}
                            placeholder="Choose a bank"
                            style={{ width: '100%' }}
                        >
                            {bank_option}

                        </Select>
                        
                    </div>
                    <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div className={kyb.miniText}>Bank Branch</div>
                        {/* <Input style={{height: '40px'}} placeholder="Bank Branch" value={branch} onChange={(e)=>setBankBranch(e.target.value)}/> */}
                        <TextInput 
                            maxLength={64}
                            data-test="input-branch"
                            placeholder={"Bank Branch"} 
                            name={"bank_branch"} 
                            value={branch} 
                            changeValue={setBankBranch}/>
                    </div>
                </div>

                <div className={kyb.displayResponsive}>
                    <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div className={kyb.miniText}>Account Name</div>
                        {/* <Input style={{height: '40px'}} placeholder="Account Name" value={account_name} onChange={(e)=>setAcctName(e)}/> */}
                        <TextInput 
                            maxLength={30}
                            data-test="input-account-name"
                            placeholder={"Account Name"} 
                            name={"account_name"} 
                            value={account_name} 
                            changeValue={setAcctName}/>

                        
                    </div>
                    <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div className={kyb.miniText}>Account Number</div>
                        <TextInput 
                            data-test="input-account-number"
                            type={"number"} 
                            placeholder={"Account Number"} 
                            name={"account_number"} 
                            value={account_number} 
                            changeValue={setAcctNum}/>
                        {/* <Input type="number" size="large" placeholder="Account Number" value={account_number} onChange={e => { if(e.target.value.length <= 16){setAcctNum(e.target.value)}}}/> */}
                    </div>

                </div>

                <div style={{marginTop: 20}}>
                    <div style={{width: isMobile ? '100%' : '49%', marginRight: isMobile ? '0%' : '2%', marginTop: isMobile ? '15px' : '0px'}}>
                        <div className={kyb.miniText}>Account Type</div>
                        <Select
                            data-test="input-account-type"
                            showSearch
                            defaultValue="SA"
                            value={account_type}
                            onChange={(ev)=>setType(ev)}
                            placeholder="Choose an account type"
                            style={{ width: '100%'}}>   
                            {accountType}
                        </Select>
                    </div>
                </div>

            <div hidden={isMobile} align="right">
            <button className={`btn--${props.segments} btn-height top-20 right-8`} 
                    data-test="submit-btn"
                    // style={{
                    //     opacity:
                    //         !bank || 
                    //         !branch ||
                    //         !account_name ||
                    //         !account_number ||
                    //         !account_type ? 0.5 : '',
                    //     cursor: 
                    //         !bank || 
                    //         !branch ||
                    //         !account_name ||
                    //         !account_number ||
                    //         !account_type ? 'not-allowed' : 'pointer'}}
                    // disabled={
                    //     !bank || 
                    //     !branch ||
                    //     !account_name ||
                    //     !account_number ||
                    //     !account_type} 
                    onClick={() => submitStep6()}
                    > {kybStep >= 6 ? "Save Changes" : "Submit and next"}</button>
                    </div>
            {props.footerNote}


        </Card>

        <div className={kyb.stickyBtn} hidden={!isMobile}>
            <button className={`btn--${props.segments} btn-mobile-width`}  
                    // style={{
                    //     opacity:
                    //         !bank || 
                    //         !branch ||
                    //         !account_name ||
                    //         !account_number ||
                    //         !account_type ? 0.5 : '',
                    //     cursor: 
                    //         !bank || 
                    //         !branch ||
                    //         !account_name ||
                    //         !account_number ||
                    //         !account_type ? 'not-allowed' : 'pointer'}}
                    // disabled={
                    //     !bank || 
                    //     !branch ||
                    //     !account_name ||
                    //     !account_number ||
                    //     !account_type} 
                    onClick={() => submitStep6()}
                    > {kybStep >= 6 ? "Save Changes" : "Submit and next"}</button>       
        </div>
        </div>
    )
}

export default BankAccount
