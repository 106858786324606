import React from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import multiwalletActions from '../multiwalletActions';
import multiwalletServices from '../multiwalletServices';
import { Card, DatePicker, Icon, Divider } from 'antd';
import { history } from '../../../store/history';

const DBMultiWalletCard = (props) => {

    const pathname = window.location.pathname;

    const { withWidth } = props;
    const analyticsData = useSelector(state => state.multiwallet.analytics);
    const dispatch = useDispatch();
    const [dateRange, setDateRange] = React.useState({ startDate: '', endDate:'' });
    const testMode = (history.location.pathname.indexOf('/test') > -1);

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    }

    const rangeSelect = (dates, dateString, id) => {
        if(dateString) {
            setDateRange({ ...dateRange, [id]: moment(dateString).format('MM-DD-YYYY') });
        } else {
            setDateRange({ ...dateRange, [id]: '' });
        }
        if (id === 'startDate') {
            getMultiwalletAnalytics(testMode, moment(dateString).format('MM-DD-YYYY'), dateRange['endDate']);
        } else {
            getMultiwalletAnalytics(testMode, dateRange['startDate'], moment(dateString).format('MM-DD-YYYY'));
        }
    };

    const sub_wallets = analyticsData && analyticsData.total_sub_users ? analyticsData.total_sub_users.toLocaleString("en-US") : 0
    
    const type1 = [
        {
            type: 'Total No.',
            value: analyticsData && analyticsData.total_sub_users ? analyticsData.total_sub_users.toLocaleString("en-US") : 0,
        }
    ]

    const type2 = [
        {
            type: 'No. of transactions',
            value: analyticsData && analyticsData.master_to_sub ? analyticsData && analyticsData.master_to_sub.transactions.toLocaleString("en-US") : 0,
        },
        {
            type: 'Amount',
            value: analyticsData && analyticsData.master_to_sub ? "₱ " + analyticsData.master_to_sub.amount.toLocaleString("en-US", {minimumFractionDigits: 2}) : '₱0.00',
        }
    ]

    const type3 = [
        {
            type: 'No. of transactions',
            value: analyticsData && analyticsData.sub_to_master ? analyticsData.sub_to_master.transactions.toLocaleString("en-US") : 0,
        },
        {
            type: 'Amount',
            value: analyticsData && analyticsData.sub_to_master ? "₱ " + analyticsData.sub_to_master.amount.toLocaleString("en-US", {minimumFractionDigits: 2}) : '₱0.00',
        }
    ]


    const getMultiwalletAnalytics = async (isTestMode, start='', end='') => {
        try {
            const res = await multiwalletServices.getMultiwalletAnalytics(isTestMode, start, end);
            if (res) {
                dispatch(multiwalletActions.setBalances(res.balances));
                const analytics = {
                    master_to_sub: res.master_to_sub,
                    sub_to_master: res.sub_to_master,
                    total_sub_users: res.sub_users
                }
                dispatch(multiwalletActions.setAnalytics(analytics));
            }
        } catch (error) {
            console.log(error);
        }
    }

    const isMac = window.screen.width * window.devicePixelRatio === 1440 && window.screen.height * window.devicePixelRatio === 900;


    React.useEffect(() => {
        getMultiwalletAnalytics(testMode);
    }, [])

    const StatsCard = (props) => {
        const { data, width, title, bgColor, color } = props;
        return (
            data && data.length && pathname !== '/multiwallet' ?
                <div style={{ width: withWidth ? '33%' :  '', padding: title === 'Sub-Wallet' && withWidth && '0 24px' }} className="top-20">
                   <div className={`m-transaction-div twoCol`}>
                        {
                            data && data.map((item, i) => 
                                <>
                                    <div align="center" style={{width: '50%'}} className={`${isMac ? "top-20" : ''}`}>
                                        <div className="type">
                                            {item.type}
                                        </div>
                                        
                                        <div className="value" style={{color: color}} >
                                            {item.value}
                                        </div>
                                    </div>
                                    <Divider type="vertical" className="divider" style={{height: isMac ? '8vh' : ''}} hidden={data.length === 1 || i > 0} />
                                </>
                            )
                        }
                   </div>
                   <div className="type top-12" align="center">{title}</div>

                </div>
                : 
            data && data.length && pathname === '/multiwallet' ?
                <div style={{width: width}} className="top-32">
                    <div className="m-multiwallet-div twoCol">
                    {
                        data && data.map((item, i) => 
                            <>
                                <div align="center" style={{width: '50%', marginTop: '15%'}}>
                                    <div className="type">
                                        {item.type}
                                    </div>
                                    
                                    <div className="value" style={{color: color, fontWeight: 600}}>
                                        {item.value}
                                    </div>
                                </div>
                                <Divider type="vertical" className="divider" style={{height: '100%'}} hidden={data.length === 1 || i > 0} />
                            </>
                        )
                    }
                    </div>
                    <div className="type top-12" align="center">{title}</div>

                </div>
            : 
            <div>None</div>
        )
    }
    return (
        <Card 
            className="multi-wallet-card tracker-card"
            style={{
                paddingBottom: pathname === '/multiwallet' ? 32 : '',
            }} 
            size='small'
        >
            {
                pathname !== '/multiwallet' ?

                <div>
                    <div className="boldTextStyle">
                        Multi-wallet
                    </div>

                    <div className="twoCol top-16">
                        <DatePicker
                            format='MM/DD/YYYY'
                            disabledDate={disabledDate}
                            defaultValue={moment()}
                            value={dateRange.startDate && moment(dateRange.startDate) || null}
                            picker="month"
                            onChange={(dates, dateString) => rangeSelect(dates, dateString, 'startDate')}
                            placeholder='Start date'
                            style={{width: '49%'}}
                            suffixIcon={
                                <div className="date-icon">
                                    <Icon type="calendar" style={{ fontSize: 22 }} />
                                </div>
                            }
                            // allowClear={false}
                        />
                        <DatePicker
                            format='MM/DD/YYYY'
                            disabledDate={disabledDate}
                            defaultValue={moment()}
                            value={dateRange.endDate && moment(dateRange.endDate) || null}
                            picker="month"
                            onChange={(dates, dateString) => rangeSelect(dates, dateString, 'endDate')}
                            style={{width: '49%'}}
                            placeholder='End date'
                            suffixIcon={
                                <div className="date-icon">
                                    <Icon type="calendar" style={{ fontSize: 22 }} />
                                </div>}
                            // allowClear={false}
                        />
                    </div>

                    <div className="top-20">
                        <div className="sub-wallet-div">
                            Total no. of Sub-wallets: <span className="smallBoldStyle">{sub_wallets}</span>
                        </div>
                        <StatsCard data={type2} width={"100%"} title={'Master to Sub-wallet'} color={'#3A73CC'} bgColor={'#3a73cc1a'} />
                        <StatsCard data={type3} width={"17vw"} title={'Sub-wallet to Master'} color={'#F5922F'} bgColor={'#f5922f1a'} />
                    </div>
                </div>
                :
                <div>
                    <div className="twoCol">
                        <div style={{display: 'flex'}} className="top-4">
                            <div className="boldTextStyle liveColor">
                                Multi-wallet
                            </div>

                            <div className="responsive-normal-text liveColor left-4">
                                | Total no. of Sub-wallets: <span className="responsive-bold-text">{sub_wallets}</span>
                            </div>
                        </div>

                        <div align="right">
                                <DatePicker
                                    format='MM/DD/YYYY'
                                    disabledDate={disabledDate}
                                    defaultValue={moment()}
                                    value={dateRange.startDate && moment(dateRange.startDate) || null}
                                    picker="month"
                                    onChange={(dates, dateString) => rangeSelect(dates, dateString, 'startDate')}
                                    placeholder='Start date'
                                    style={{width: '40%'}}
                                    suffixIcon={
                                        <div className="date-icon">
                                            <Icon type="calendar" style={{ fontSize: 22 }} />
                                        </div>
                                    }
                                    // allowClear={false}
                                />
                                <DatePicker
                                    format='MM/DD/YYYY'
                                    className="left-8"
                                    disabledDate={disabledDate}
                                    defaultValue={moment()}
                                    value={dateRange.endDate && moment(dateRange.endDate) || null}
                                    picker="month"
                                    onChange={(dates, dateString) => rangeSelect(dates, dateString, 'endDate')}
                                    style={{width: '40%'}}
                                    placeholder='End date'
                                    suffixIcon={
                                        <div className="date-icon">
                                            <Icon type="calendar" style={{ fontSize: 22 }} />
                                        </div>}
                                    // allowClear={false}
                                />
                        </div>
                    </div>
                    
                    <div className="twoCol">
                        <StatsCard data={type2} width={"48%"} title={'Master wallet to Sub-wallet'} color={'#3A73CC'} bgColor={'#3a73cc1a'} />
                        <StatsCard data={type3} width={"48%"} title={'Sub-wallet to Master wallet'} color={'#F5922F'} bgColor={'#f5922f1a'} />
                    </div>
                </div>
            }
        </Card>
    )
}

export default DBMultiWalletCard;