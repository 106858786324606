import React, { useEffect } from "react";
import { Input, Button, Checkbox, Divider } from "antd";
import { useDispatch, useSelector } from "../../../__test__/react-redux-hooks";
import {
  login,
  fb_login,
  google_login,
  LoginActionTypes,
} from "../../../components/login/authAction";
import { history } from "../../../store/history";
import { isMobile } from "react-device-detect";
import axios from "axios";
import SocialButton from "../../../components/social/socialButton";
import BuxAlert from "../../../components/alert/alert";

import NewBuxLogo from "../../../static/images/signup_and_login/new_bux_logo2.svg";
import GoogleIcon from "../../../static/images/signup_and_login/google_icon.svg";
import FacebookIcon from "../../../static/images/signup_and_login/facebook_icon.svg";

const isHighReso =
  window.screen.width * window.devicePixelRatio >= 1440 &&
  window.screen.height * window.devicePixelRatio >= 900;

const Login = () => {
  const dispatch = useDispatch();

  const loginLoading = useSelector((state) => state.login.loading);
  const alert = useSelector((state) => state.alert.showAlert);

  const testMode = history.location.pathname.indexOf("/test") > -1;
  const isSubDomain =
    process.env.REACT_APP_ROOT_URL != window.location.hostname;

  const [businessName, setBusinessName] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [logoUrl, setLogoUrl] = React.useState(null);
  const [signupEnabled, setSignupEnabled] = React.useState(false);
  let remove_part = `.${process.env.REACT_APP_SUB_URL}`;
  const trade_name = window.location.hostname.replace(remove_part, "");

  async function getDetails() {
    try {
      let url =
        process.env.REACT_APP_API_URL +
        `/api/enterprise/${trade_name}/details/`;

      let response = await axios.get(url);
      if (response.data.status === "failed") {
        window.location.href = process.env.REACT_APP_BASE_URL;
      } else {
        if (!response.data.multiwallet) {
          window.location.href = process.env.REACT_APP_BASE_URL;
        } else {
          setBusinessName(response.data.business_name);
          setLogoUrl(response.data.banner_url);
          setSignupEnabled(response.data.enterprise_signup_enabled);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (isSubDomain) {
      getDetails();
    }
  }, []);

  function submitCreds() {
    let params = {
      username: username,
      password: password,
    };
    dispatch(login(params));
  }

  function fbSubmitCreds(token) {
    dispatch(fb_login(token));
  }

  function handleFBSocialLogin(user) {
    fbSubmitCreds(user.token);
  }

  function googleSubmitCreds(token) {
    dispatch(google_login(token));
  }

  function handleGoogleSocialLogin(user) {
    googleSubmitCreds(user.token);
  }

  function handleSocialLoginFailure(err) {
    console.error(err);
  }

  function handleForgotPasswordClick() {
    if (testMode) {
      history.push("/test/forgot_password");
    } else {
      history.push("/forgot_password");
    }
  }

  function handleSignupClick() {
    dispatch({
      type: LoginActionTypes.CLEAR,
    });
    if (testMode) {
      history.push("/test/signup");
    } else {
      history.push("/signup");
    }
  }

  return (
    <div
      id="terms"
      style={{
        ...style.mainDiv,
        width: isMobile ? "100%" : isHighReso ? "529px" : "430px",
        overflowY: "auto",
      }}
    >
      {isSubDomain && logoUrl && logoUrl != "" ? (
        <div
          style={{
            textAlign: "center",
            margin: "12px 0 24px 0",
            backgroundImage: `url(${logoUrl})`,
            backgroundSize: "220px",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height: 74,
          }}
        ></div>
      ) : isMobile ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={NewBuxLogo} alt="#" />
        </div>
      ) : (
        <div style={style.login}>Log in</div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: isHighReso || isMobile ? "40px" : "20px",
        }}
      >
        <div>
          <BuxAlert width={"312px"} />
          <div>
            <div style={style.label}>Email</div>
            <Input
              type="large"
              onChange={(e) => setUsername(e.target.value)}
              style={{ width: "312px", height: "40px" }}
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <div style={style.label}>Password</div>
            <Input.Password
              type="large"
              onChange={(e) => setPassword(e.target.value)}
              style={{ width: "312px", height: "40px" }}
            />
          </div>
          <div style={{ width: "312px", marginTop: "10px" }} align="right">
            {/* <Checkbox>
                            <span style={{fontSize: '14px', color: '#2b2d33'}}>Remember me?</span>
                        </Checkbox> */}
            <a
              style={{ fontSize: "14px", color: "#f5922f", fontWeight: "bold" }}
              onClick={() => handleForgotPasswordClick()}
            >
              Forgot password?
            </a>
          </div>
          <div style={{ marginTop: "30px" }}>
            <Button
              loading={loginLoading}
              disabled={username === "" || password === ""}
              style={{
                ...style.loginBtn,
                opacity: username === "" || password === "" ? 0.7 : 1,
              }}
              onClick={() => submitCreds()}
            >
              Log in
            </Button>
          </div>
          {trade_name === "cashbux" ? (
            ""
          ) : (
            <>
              <Divider hidden={isSubDomain}>or</Divider>
              <div style={{ marginTop: "20px" }} hidden={isSubDomain}>
                <SocialButton
                  provider="google"
                  appId={process.env.REACT_APP_GOOGLE_OAUTH_KEY}
                  onLoginSuccess={handleGoogleSocialLogin}
                  onLoginFailure={handleSocialLoginFailure}
                  style={style.googleBtn}
                >
                  <img src={GoogleIcon} alt="#" style={{ float: "left" }} />
                  Log in with Google
                </SocialButton>
              </div>
              <div style={{ marginTop: "20px" }} hidden={true}>
                <SocialButton
                  provider="facebook"
                  appId={process.env.REACT_APP_FB_ID}
                  onLoginSuccess={handleFBSocialLogin}
                  onLoginFailure={handleSocialLoginFailure}
                  style={style.facebookBtn}
                >
                  <img
                    src={FacebookIcon}
                    alt="#"
                    style={{
                      float: "left",
                      marginTop: "5px",
                      marginLeft: "5px",
                    }}
                  />
                  Log in with Facebook
                </SocialButton>
              </div>
              {(!isSubDomain || signupEnabled) && <div
                style={{
                  display: "flex",
                  marginTop: "30px",
                  justifyContent: "center",
                }}
              >
                <div style={style.noAccount}>No Account? </div>
                <a style={style.signUp} onClick={() => handleSignupClick()}>
                  Sign up
                </a>
              </div>}
            </>
          )}
          <div
            hidden={!isSubDomain}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "6px 0px -14px 0px",
            }}
          >
            <span style={{ fontSize: 12, color: "#909196" }}>Powered by</span>
            <img
              src={NewBuxLogo}
              style={{ width: 50, height: 38, paddingLeft: 2 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const style = {
  mainDiv: {
    height: "100%",
    borderRadius: !isMobile && "8px",
    backgroundColor: "#fff",
    boxShadow: "0px 8px 24px rgba(0, 35, 11, 0.12)",
    paddingTop: isHighReso ? "50px" : "30px",
    paddingBottom: "30px",
  },
  login: {
    display: "flex",
    justifyContent: "center",
    fontSize: "28px",
    color: "#000",
    fontWeight: "bold",
  },
  label: {
    fontSize: "14px",
    color: "rgba(43, 45, 50, 0.8)",
    fontWeight: "normal",
  },
  loginBtn: {
    width: "312px",
    height: "40px",
    borderRadius: "4px",
    border: "1px solid #F5922F",
    backgroundColor: "#F5922F",
    fontSize: "16px",
    color: "#fff",
    fontWeight: "bold",
  },
  googleBtn: {
    height: "40px",
    backgroundColor: "#4285F4",
    width: "312px",
    borderRadius: "4px",
    border: "1px solid #4285F4",
    color: "#FFF",
    fontSize: "16px",
    fontWeight: "bold",
  },
  facebookBtn: {
    height: "40px",
    backgroundColor: "#1877F2",
    width: "312px",
    borderRadius: "4px",
    border: "1px solid #1877F2",
    color: "#FFF",
    fontSize: "16px",
    fontWeight: "bold",
  },
  noAccount: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#2b2d33",
    marginRight: "5px",
  },
  signUp: {
    fontWeight: "700",
    fontSize: "16px",
    color: "#f5922f",
  },
};

export default Login;

