import React, { useState } from "react";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Content from './content';

const TransactionHistory = ({isMobile, custId, isTestMode}) => {
  const [showContent, setShowContent] = useState(true);
  const kyc_lvl = localStorage.getItem('Level')
  const isMulitwallet = localStorage.getItem("is_multiwallet") === 'true';
  const userType = localStorage.getItem("userType");
  const isChild = localStorage.getItem("is_child") === 'true';
  const segments = localStorage.getItem('segments') 

  const styles = {
    root: {
      boxShadow: isMobile && '0px 2px 4px rgba(0, 0, 0, 0.05)',
    },
    mainBtn: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: '#FFFFFF',
      padding: 12,
      alignItems: 'center',
      borderRadius: `${showContent && !isTestMode ? '4px 4px 0px 0px' : showContent && isTestMode? '0' : '4px 4px 4px 4px'}`,
      height: 62,
    },
    regularFont: {
      fontSize: 16,
      color: '#2B2D33',
      fontWeight: '600'
    },
    test: {
      backgroundColor: '#454545',
      color: '#FFF',
      fontSize: 14,
      fontWeight: '900',
      borderRadius: '4px 4px 0px 0px',
      padding: 4,
      textAlign: 'center'
    },
  }

  return (
    <div style={styles.root}>
      <div hidden={!isTestMode} style={styles.test}>Test Data</div>
      <div style={styles.mainBtn}>
        <div className="flex-center">
          <label className="boldTextStyle">Transaction History</label>
        </div>
        <a style={{ display: 'flex' }} 
          hidden={ isChild ||(isMulitwallet && userType === 'CO') || !custId && kyc_lvl >= 0} 
          onClick={() => setShowContent(!showContent)}>
            { showContent ? 
                <ExpandLessIcon className={`text-${segments}`} style={{ fontSize: 32 }} /> : 
                <ExpandMoreIcon className={`text-${segments}`} style={{ fontSize: 32 }} />
            }
          </a>
      </div>
      { showContent ? <Content sideBar={true} custId={custId} isTestMode={isTestMode} /> : null}
    </div>
  )
}

export default TransactionHistory;