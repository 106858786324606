import React from 'react'
import { Spin, message } from 'antd'
import DynamicQRPHStyles from './styles.module.css'
import { DynamicQRPHServices } from './services';
import axios from 'axios'
import QRPHImage from '../qrph_image/qrph_image';

const DynamicQRPH = props => {
    const segments = localStorage.getItem("segments")

    const [qrphImageIsRendering, setQRPHImageIsRendering] = React.useState(true);
    const [qrphImageOpacity, setQRPHImageOpacity] = React.useState(0);
    const [isDownloading, setIsDownloading] = React.useState(false);
    
    const {
        staticQRPHImageURL,
        displayedName,
        amount,
        notes,
        paymentLinkUid,
    } = props

    const saveDynamicQRPHBuxImage = async () => {
        setIsDownloading(true);
        try {
            const buxImageUrl = await DynamicQRPHServices.saveDynamicQRPHBuxImageApi(
                paymentLinkUid,
            );
            const buxImageUrlResponse = await axios.get(
                buxImageUrl, 
                { 
                    responseType: 'blob',
                },
            );
            const buxImageFile = new Blob(
                [
                    buxImageUrlResponse.data,
                ],
                { type: "image/*" }
            );
            
            const link = document.createElement('a');
            link.href = URL.createObjectURL(buxImageFile);
            link.download = `qr-code-${Date.now()}.png`;
            document.body.appendChild(link); 
            link.click(); 
            document.body.removeChild(link); 
        }
        catch (error) {
            message.error("Failed to download image.");
        }
        setIsDownloading(false);
    }

    return(
        <div
            style={{ display: "flex" }}
        >
            <div className={DynamicQRPHStyles.drawerBodyContent}>
                <div 
                    className={DynamicQRPHStyles.bodyLoading}
                    hidden={!qrphImageIsRendering}
                >
                    <Spin 
                        size='large'
                        className={DynamicQRPHStyles.spinner} 
                    />
                    <div
                        style={{
                            fontWeight: "600",
                            fontSize: "16px",
                            color: "#2B2D33",
                        }}
                    >
                        Loading QR image...
                    </div>
                </div>

                <div 
                    className={DynamicQRPHStyles.bodyContent}
                    style={{
                        opacity: qrphImageOpacity,
                    }}
                    hidden={qrphImageIsRendering}
                >
                    <QRPHImage
                        staticQRPHImageURL={staticQRPHImageURL}
                        displayedName={displayedName}
                        setQRPHImageIsRendering={setQRPHImageIsRendering}
                        setQRPHImageOpacity={setQRPHImageOpacity}
                        isStaticQRPH={false}
                        amount={amount}
                        notes={notes}
                    />

                    <button 
                        className={`btn--${segments || "biz"} btn-height`}
                        style={{
                            borderRadius: "4px",
                            width: "95%"
                        }}
                        onClick={saveDynamicQRPHBuxImage}
                        disabled={isDownloading}
                    >
                        <div hidden={!isDownloading}>
                            <Spin className={DynamicQRPHStyles.spinner} />
                        </div>
                        <div hidden={isDownloading}>
                            Download QR
                        </div>
                    </button>
                </div>
            </div>
        </div>     
    )
}

export default DynamicQRPH;