import React, {useEffect, useState} from 'react'
import axios from 'axios'
import accountStyle from '../../../static/css/Account';
import {Select, Input, message, Modal, Spin, Typography, Alert, Icon} from 'antd'
import { Grid } from "@material-ui/core";
import {BANKS} from '../../constants/constants'
import {isMobile} from 'react-device-detect'
import SaveBank from '../../../static/icons/savebank.svg'
import {history} from '../../../store/history';
import { getLatestBalance } from '../../../screens/Transfer/api/user/getLatestBalance';
import { getUserPayoutBank } from '../../payout_drawer/api/getUserPayoutBank';
import CreateBankAccount from './CreateBankAccount';
import { getUbpBanks } from '../../../screens/Transfer/routes/merchant/api/getUbpBanks';

const {Option} = Select

const NewBankAccount = props => {
    const acct = accountStyle();

    const userType = localStorage.getItem("userType")
    const segments = localStorage.getItem("segments")
    const hasCashbuxPrivilege = localStorage.getItem("has_cashbux_privilege") === 'true';
    const isPayoutRestricted = localStorage.getItem("is_payout_restricted") === 'true';
    const [bank, setBank] = React.useState("")
    const [acctName, setAcctName] = React.useState("")
    const [acctNum, setAcctNum] = React.useState("")
    const [fullName, setFullName] = React.useState("")
    const [alertMsg, setAlertMsg] = React.useState(true)
    const [errorMsg, setErrorMsg] = React.useState("")
    const [saveModal, setSaveModal] = React.useState(false)
    const [showLoading,setShowLoading] = React.useState(true)
    const [isDisabled, setIsDisabled] = React.useState(true)

    const [restrictedBanks, setRestrictedBanks] = useState([]);
    const [banksList, setBanksList] = useState([]);

    useEffect(() => {
        const fetchBankData = async () => {
            try {
              const response = await getUserPayoutBank();
              const banks = response.banks.map((bank) => ({ name: bank.bank_name, code: bank.code }));
              setRestrictedBanks(banks);
            } catch (error) {
              console.log(error);
            }
          };

        const fetchUbpBanksData = async () => {
          try {
            const excludeEWallets = true;
            const response = await getUbpBanks(excludeEWallets);
            const banks = response.banks;
            setBanksList(banks);
          } catch (error) {
            console.log(error)
          }
        }
      
          fetchBankData();
          fetchUbpBanksData();
    }, []);

    useEffect(() => {
      isPayoutRestricted && setBanksList(restrictedBanks.sort());
    }, [restrictedBanks, isPayoutRestricted])

    const banks = isPayoutRestricted ? restrictedBanks : BANKS;
    banks.sort();

    let bank_option = banks.length > 0 && banks.map((item, i) => {
      
      return (
        <Option key={i} value={item}>{item}</Option>
      )
    }, this)

    
    function setBanks(value){
        setBank(value)
    }

    function save(){
        setShowLoading(false)
        let params = {
            bank,
            account_name:acctName,
            account_number: isSelectedBank() ? acctNum : "0"+acctNum,
        }

        submitBank(params)
        console.log(params)
        
    }

    async function submitBank(val){
        let yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }
        let url = process.env.REACT_APP_API_URL + "/api/bank_accounts/"
        
        axios.post(url,val, yourConfig).then(response => {
            setBank("");
            setAcctName("");
            setAcctNum("");
            setShowLoading(true);
            setSaveModal(true);
            setAlertMsg(true);
            // getBankAccount();
            console.log(response)
          })
         .catch(error => {
            setErrorMsg(error.response.data)
            setAlertMsg(false)
            setShowLoading(true)
        })
    }

    async function getBankAccount(){
        let yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let url = process.env.REACT_APP_API_URL + "/api/bank_accounts/"

             
        let res = await fetch(url, yourConfig);

        if (res.ok) { // if HTTP-status is 200-299
        // get the response body (the method explained below)
        let json = await res.json();
        let maxLength = userType == "ME" ? 3 : 2
        
        if(json.length == maxLength){
            window.location.href = '/account_settings#bank_account'
        }

        } else {
        message.error("HTTP-Error: " + res.status, 5);
        }
    }

    function handleAccountNameChange(value) {
        let specialChar = (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value))
        let number = (/[0-9]/.test(value))

        if(value.length == 30) {
            setAlertMsg(false)
            setErrorMsg("Account Name max 30 characters reached.")
            setIsDisabled(true)
        }

        else if(specialChar || number){
            setAlertMsg(false)
            setErrorMsg("Account Name must not contain special character or number")
            setIsDisabled(true)
        }
        
        else {
            setAlertMsg(true)
            setAcctName(value)
        }
    }

    function handleChangeAcctNumber(value) {

        if(isSelectedBank() && value.length <= 16) {
            setAcctNum(value)
            setAlertMsg(true)
        }

        else if(!isSelectedBank() && value.length <= 10) {
            setAcctNum(value)
            setAlertMsg(true)
        }
 
    }

    useEffect(() => {
        let fname = localStorage.getItem("firstName")
        let lname = localStorage.getItem("lastName")
        setFullName(fname+" "+lname);
        getBankAccount();
    }, [])

    useEffect(() => {
        if(bank == "" || acctName == "" || acctNum == "" || !alertMsg) {
            setIsDisabled(true);
        }

        else {
            setIsDisabled(false);
        }

    },[bank, acctName, acctNum])

    const isSelectedBank = () => {
        if(bank === 'GCash (G-Xchange Inc.)' || bank === 'Paymaya' || bank === 'DCPay Philippines, Inc.(Coins.ph)' && bank) {
            return false;
        }
        return true;
    }

    return (
      <>
        {hasCashbuxPrivilege ? (
          <div
            style={{
              margin: "5em 2.5em 2.5em 0",
              display: "flex",
              gap: "1em",
              flexDirection: "column",
            }}
          >
            <CreateBankAccount banksList={banksList} />
          </div>
        ) : (
          <div
            className={acct.mainBody}
            style={{ margin: !isMobile && "52px 0 0 0" }}
          >
            <div hidden={!isMobile} style={styles.mobileHeader}>
              <a
                className={`link-text--${segments}`}
                onClick={() => history.goBack()}
              >
                <Icon type="arrow-left" theme="outlined" />
              </a>
              <Typography style={styles.title}>
                Link new bank account
              </Typography>
            </div>
            <div
              className={!isMobile && acct.menu}
              style={{
                background: "transparent",
                padding: isMobile ? 0 : "32px 32px 0px 0px",
              }}
              align="center"
            >
              <Modal
                visible={saveModal}
                onCancel={() => setSaveModal(false)}
                footer={null}
              >
                <div align="center">
                  <img style={{ marginTop: "25px" }} src={SaveBank} />
                  <div className={acct.modalHeader}>
                    Bank account successfully linked!
                  </div>
                  <div align="right" style={{ paddingTop: 24 }}>
                    <button
                      className={`btn--${segments}`}
                      onClick={() =>
                        (window.location.href =
                          "/account_settings#bank_account")
                      }
                    >
                      Done
                    </button>
                  </div>
                </div>
              </Modal>

              <div style={{ width: isMobile ? "100%" : 540 }}>
                <div className={acct.bankHeader} align="left" hidden={isMobile}>
                  Link new bank account
                </div>

                <div
                  className={acct.bank}
                  align="left"
                  style={{ width: isMobile ? "100%" : 540 }}
                >
                  <div
                    className={acct.infoDiv}
                    style={{ marginBottom: 20 }}
                    hidden={localStorage.getItem("userType") != "CO"}
                  >
                    <Alert
                      type="info"
                      message={
                        <div>
                          Your secondary bank account is for other API fees
                          only.
                        </div>
                      }
                    />
                  </div>

                  <div className={acct.alert} hidden={alertMsg}>
                    {errorMsg}
                  </div>

                  <div className={acct.placeholder}>Bank</div>

                  <Select
                    showSearch
                    onChange={(ev) => setBanks(ev)}
                    placeholder="Choose a bank"
                    style={{ width: "100%", marginBottom: "10px" }}
                  >
                    {bank_option}
                  </Select>

                  <Grid
                    container
                    className={isMobile ? acct.none : acct.acctDiv}
                  >
                    <Grid item lg={6} xs={12}>
                      <div className={acct.placeholder}>Account name</div>
                      <Input
                        maxLength={30}
                        style={{
                          width: "100%",
                          border: !alertMsg ? "1px solid red" : "",
                        }}
                        placeholder="Account Name"
                        onChange={(e) =>
                          handleAccountNameChange(e.target.value)
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      xs={12}
                      className={isMobile ? "" : ""}
                      style={{ paddingLeft: isMobile ? "" : "11px" }}
                    >
                      <div className={acct.placeholder}>
                        {isSelectedBank() ? "Account Number" : "Mobile Number"}
                      </div>
                      <Input
                        type="number"
                        value={acctNum}
                        style={{
                          width: "100%",
                        }}
                        addonBefore={isSelectedBank() ? null : "+63"}
                        placeholder={
                          isSelectedBank() ? "Account Number" : "Mobile Number"
                        }
                        onChange={(e) => handleChangeAcctNumber(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <div className={acct.infoDiv} align={"right"}>
                    <button
                      className={`outline-btn--${segments}`}
                      onClick={() =>
                        history.push("/account_settings#bank_account")
                      }
                      hidden={!showLoading}
                      style={{ marginRight: 8 }}
                    >
                      Cancel
                    </button>

                    <button
                      className={`btn--${segments}`}
                      style={{
                        opacity: isDisabled ? "0.5" : "",
                        cursor: isDisabled ? "not-allowed" : "pointer",
                      }}
                      disabled={isDisabled}
                      onClick={() => save()}
                      hidden={!showLoading}
                    >
                      Save
                    </button>

                    <div hidden={showLoading}>
                      <Spin size="large" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
}

const styles = {
    mobileHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0px 16px',
        backgroundColor: '#FFF',
        margin: '0 0 32px 0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)'
    },
    title: {
        color: '#2B2D32',
        fontSize: 18,
        fontWeight: 'bold',
        padding: '0 16px'
    },
}

export default NewBankAccount
