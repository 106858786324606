import React,{} from 'react';
import { Card, Typography, Button, Icon } from 'antd';
import { TestModeImg } from '../../../static/icons/index';
import { history } from '../../../store/history';
import '../../../styles/screens/dashboard.scss';

const TestModeCard = (props) => {

  const testMode = history.location.pathname.indexOf('/test') > -1
  const segments = localStorage.getItem("segments")
  const { isDellReso } = props;
  
  return (
    <div
      className="accountCard" style={{ padding: 0, height: '100%' }} align="left"
    >
      <div style={{display: 'flex'}}>
        <div style={{position: 'relative'}}>

        <img style={styles.img} src={TestModeImg} alt='No Image' />
  
        </div>
        <div style={styles.body}>
          <div align='left'>
            <Typography style={styles.title}>Test Mode is enabled</Typography>
            <Typography style={styles.msg}>Your actions in this mode do not affect any live existing transactions.</Typography>
          </div>

          <div align='right' style={{marginTop: '6px'}} hidden={testMode && localStorage.getItem("userType") === "SW"}>
            <Button type='link' href='/dashboard' className={`text-${segments}`} style={styles.link}>Switch to Live Mode<Icon type='right' /></Button>
          </div>

        </div>
    
      </div>
    </div>
  )
}

const styles = {
  root: {
    height: 124,
    padding: 0,
    borderTop: '4px #424242 solid',
    display: 'flex',
    width: '48vw'
  },
  body: {
    padding: '12px 18px',
  },
  title: {
    color: '#000000',
    fontSize: 20,
    fontWeight: 'bold'
  },
  msg: {
    color: '#000',
    fontSize: 16,
  },
  link: {
    textAlign: 'right',
    color: '#F5922F',
    fontSize: 17,
    fontWeight: '600'
  },
  img: {
    objectFit: 'contain'
  }
}

export default TestModeCard;