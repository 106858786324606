import React from 'react'
import axios from 'axios'
import { Select, Alert, Input, Divider, Button, Spin, Typography, message as toastMessage, Checkbox } from 'antd'
import * as Style from '../../layouts/AppBar/appBarComponent/appBarFormStyle'
import { history } from '../../store/history'
import { isMobile, isIOS, isAndroid, isWinPhone } from 'react-device-detect'
import share_email from '../../static/icons/share_email.svg'
import share_messenger from '../../static/icons/share_messenger.svg';
import share_sms from '../../static/icons/share_sms.png';
import share_viber from '../../static/icons/share_viber.png';
import share_whatsapp from '../../static/icons/share_whatsapp.png';
import PaymentSchedDiv from './payment_schedule'

const GenerateLink = props => {

  const {
    setShowDrawer, disabled, setIsSched, color
  } = props

  const maxAmount = process.env.REACT_APP_MAXIMUM_AMOUNT ? process.env.REACT_APP_MAXIMUM_AMOUNT : 30000
  const minAmount = process.env.REACT_APP_MINIMUM_AMOUNT ? process.env.REACT_APP_MINIMUM_AMOUNT : 50
  const testMode = (history.location.pathname.indexOf('/test') > -1 && localStorage.getItem("userType") == "CO");
  const userRole = localStorage.getItem("userType")
  const isMultiwallet = localStorage.getItem('is_multiwallet');
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL
  const FB_APP_ID = process.env.REACT_APP_FB_ID
  const FB_SHARE_URL = process.env.REACT_APP_FB_SHARE_URL

  const { Option } = Select
  const { TextArea } = Input

  const [generation_form, hideGenerationForm] = React.useState(false);
  const [message, setMessage] = React.useState(true)
  const [alertAmountError, setAlertAmountError] = React.useState(false)
  const [amount, setAmount] = React.useState("");
  const [tranxFeeShouldered, setTranxFeeShouldered] = React.useState(false);
  const [description, setDescription] = React.useState("");
  const [expire_at, setExpireAt] = React.useState("6");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [loading, setLoading] = React.useState(true)
  const [shareForm, setShareForm] = React.useState(true)
  const [response, setResponse] = React.useState({})
  const [alert, setAlert] = React.useState(true)
  const [checked, setChecked] = React.useState(false)
  const [schedHidden, setSchedHidden] = React.useState(true)
  const segments = localStorage.getItem('segments')

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      generateCodeNow()
    }
  }

  function handleExpiration(value) {
    setExpireAt(value)
  }

  const resetCodeDetails = () => {
    setShowDrawer(false)
    setAlertAmountError(false)
    setAlert(true)
    setShareForm(true)
    hideGenerationForm(false)
    setAmount("")
    setDescription("")
    setIsSched(false)
  }

  let plink
  let emailBody = `Hi BUx Customer
      
    Merchant has sent you a payment link. You may click or copy the Payment Link for you to be able to access the checkout page.
    
    Payment Link Details:
    Amount: ${response.amount}
    Payment Link: ${REACT_APP_BASE_URL}${testMode ? '/test/checkout/' : '/checkout/'}${response.uid}
    Payment link will expire on: ${response.expiry}
    Payment details and merchant information can be found on the URL.
    
    Thank you!`
  let y = encodeURIComponent(emailBody)

  let emailSubject = `Payment Request from ${localStorage.getItem('firstName')} ${localStorage.getItem('lastName')}: Payment Link will expire on ${response.expiry}`

  const ref = React.useRef()

  const smsDisplay = (data) => {
    if (isAndroid) {
      return (<a href={`sms:?body=${y}`}>
        <img src={share_sms} style={{ width: '40px', marginRight: '5px' }} alt="#" /></a>);
    }
    else if (isIOS) {
      return (<a href={`sms:?&body=${y}`}>
        <img src={share_sms} style={{ width: '40px', marginRight: '5px' }} alt="#" /></a>);
    }
    else if (isWinPhone) {
      return (<a href={`sms:/?body=${y}`}>
        <img src={share_sms} style={{ width: '40px', marginRight: '5px' }} alt="#" /></a>);
    }
    else {
      return;
    }
  }

  const fbDisplay = (data) => {
    if (isMobile) {
      return (<a href={`fb-messenger://share/?app_id=${FB_APP_ID}&link=${REACT_APP_BASE_URL}/payment/${data.uid}/%3Futm_source%3Dbux%26utm_medium%3Dcode-facebook`}><img src={share_messenger} style={{ width: '40px', marginRight: '5px' }} alt="#" /></a>);
    }
    else {
      return (<a href={`${FB_SHARE_URL}redirect_uri=${REACT_APP_BASE_URL}/dashboard&app_id=${FB_APP_ID}&link=${REACT_APP_BASE_URL}${testMode ? '/test/checkout/' : '/checkout/'}${response.uid}/%3Futm_source%3Dbux%26utm_medium%3Dcode-facebook`} target="_blank"><img src={share_messenger} style={{ width: '40px', marginRight: '5px' }} alt="#" /></a>);
    }
  }


  const viberDisplay = (data) => {
    if (isMobile) {
      return (<a href={`viber://pa?chatURI=buxph&text=${y}`}><img src={share_viber} style={{ width: '40px', marginRight: '5px' }} alt="#" /></a>);
    }
    else {
      return;
    }
  }

  const whatsappDisplay = (data) => {
    if (isMobile) {
      return (<a href={`whatsapp://send?text=${y}`}><img src={share_whatsapp} style={{ width: '40px', marginRight: '5px' }} alt="#" /></a>);
    }
    else {
      return;
    }
  }

  const checkTranxFee = async () => {
    let yourConfig = {
      headers: {
        Authorization: "Token " + localStorage.getItem("jwtToken")
      }
    }
    try {
      let response = await axios.get(
        process.env.REACT_APP_API_URL + "/api/settings/",
        yourConfig
      );
      setTranxFeeShouldered(response.data.shoulder_enabled);

    }
    catch (error) {
      if ((error.response) && (error.response.status == 401)) {
        console.log(error)
      }
    }
  }

  async function generateCodeNow() {

    let params = {
      amount: amount,
      description: description,
      expireAt: expire_at,
      name: name,
      email: email,
      phone: phone,
    };


    if ((localStorage.getItem("userType") == "ME") && (parseFloat(amount) < minAmount || parseFloat(amount) > maxAmount)) {
      setAlertAmountError(true)
      setMessage("Amount must be 50 PHP up to 30,000 PHP")
      window.location.href = '#top'
    }

    // else if((localStorage.getItem("userType") == "CO") && (parseFloat(amount) < minAmount)) {
    //   setAlertAmountError(true)
    //   setMessage("Amount must be 50 PHP up to 80,000 PHP")
    //   window.location.href = '#top'
    // }

    else if (description == "" || name == "" || phone == "" || email == "") {
      setAlertAmountError(true)
      setMessage("Please complete the form")
      window.location.href = '#top'
    }

    else if (description.length > 200) {
      setAlertAmountError(true)
      setMessage("Description must 200 characters or below")
      window.location.href = '#top'
    }
    else if (phone.length != 10) {
      setAlertAmountError(true)
      setMessage("Invalid mobile number format")
      window.location.href = '#top'
    }

    else if (email.length > 100) {
      setAlertAmountError(true)
      setMessage("Email must 100 characters or below")
      window.location.href = '#top'
    }

    else {
      setLoading(false)
      setAlertAmountError(false)
      hideGenerationForm(true)
      try {
        const url = testMode ? '/api/sandbox/generate/' : '/api/orders/generate/'

        const yourConfig = {
          headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
          }
        }

        let response = await axios.post(
          process.env.REACT_APP_API_URL + url, params,
          yourConfig
        );

        if (response.data.status == "success") {

          if(checked){
            setIsSched(true)  
            setSchedHidden(false)
          }
          setResponse(response.data.data)
          setLoading(true)
          setShareForm(false)
          // GTM trigger
          window.dataLayer.push({
            event: 'generatePaymentLink',
            eventLabel: `${expire_at} hours`,
            eventValue: amount,
          });
        }
        else {
          hideGenerationForm(false)
          setLoading(true)
          setAlertAmountError(true)
          setMessage(response.data.message)
          window.location.href = '#top'
        }

      } catch (error) {
        throw new Error(error.message);
      }
    }
  }
  React.useEffect(() => {
    checkTranxFee()
  }, [])

  return (
    <div style={{ marginTop: 12 }}>
      {
      disabled ? 
      <div align="center" style={{padding:'15px 0px'}}>
          <Typography>Your Generate Payment Link feature is OFF.</Typography>
          <Typography>Contact us to enable payment link generation.</Typography>
      </div> : 
      <div>
        <div hidden={generation_form} >

          <Alert ref={ref} message={message} type="error" style={{ display: alertAmountError ? "block" : "none", borderLeft: '4px solid red' }} />
          <div style={Style.labelStyle}>Amount (₱)</div>
          <Input value={amount} type="number" onChange={e => {
            if (e.target.value.includes(".")) {
              if (e.target.value.split(".")[1].length > 2) {
                setAmount(parseFloat(e.target.value).toFixed(2))
              } else {
                setAmount(e.target.value)
              }
            } else {
              setAmount(e.target.value)
            }
          }} 
          onWheel={(e) => {
            e.preventDefault();
            e.target.blur();
          }}
          />
          <div style={{ paddingBottom: '10px' }}><span style={{ fontFamily: 'Inter', fontWeight: '500', fontStyle: 'normal', fontSize: '10px' }}>
            <strong>Transaction Fee</strong>{tranxFeeShouldered ? ' will be deducted from your total payment collection.' : ` will be added to the total amount upon customer checkout.`} </span></div>
          <div style={Style.labelStyle}>Description</div>
          <TextArea value={description} rows="4" onChange={e => setDescription(e.target.value)} style={{ marginBottom: '20px' }} />
          <div style={Style.labelStyle}>Payment Link Expiration</div>
          <Select
            className="btn-mobile-width bottom-16"
            showSearch
            onChange={handleExpiration}
            defaultValue="6"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="6">6 hours</Option>
            <Option value="12">12 hours</Option>
            <Option value="24">1 day</Option>
            <Option value="48">2 days</Option>
            <Option value="72">3 days</Option>
            <Option value="168">7 days</Option>
          </Select>

          <div style={Style.customerHeader}>Customer’s Information</div>
          <Divider style={{ marginTop: '10px', marginBottom: '10px' }}></Divider>

          <div style={Style.labelStyle}>Customer Name</div>
          <Input
            onChange={(e) => setName(e.target.value)}
            maxLength={60} />

          <div style={Style.labelStyle}>Customer Email</div>
          <Input
            onChange={(e) => setEmail(e.target.value)}
            maxLength={100} />

          <div style={Style.labelStyle}>Customer’s Mobile Number </div>
          <Input type="number" className="bottom-20" addonBefore="+63" maxLength={10} value={phone} onChange={e => { if (e.target.value.length <= 10) { setPhone(e.target.value) } }} onKeyPress={(e) => handleKeyPress(e)} 
          onWheel={(e) => {
            e.preventDefault();
            e.target.blur();
          }}
          />
          
          <label class="container" style={{marginBottom: '25px'}} hidden={userRole == 'SW'}> <div style={Style.paymetSchedText}>Set a payment request schedule</div> 
              <input type="checkbox" checked={checked} onChange={(e)=>{ setChecked(e.target.checked); setSchedHidden(!e.target.checked)}}/>
              <span class="checkmark" style={{backgroundColor: checked ? color : '#eee'}}></span>
          </label>

          <button className={`btn--${segments} btn-height btn-mobile-width`}
            block
            onClick={() => generateCodeNow()}
            disabled={amount == "" || description == "" || phone == "" || email == "" || name == "" ? true : false}>
            Generate payment link
          </button>


        </div>
        <div hidden={shareForm}>
          <PaymentSchedDiv 
            amount={amount}
            description={description}
            fullName={name}
            email={email}
            contact={phone}
            resetCodeDetails={resetCodeDetails}
            response={response}
            schedHidden={schedHidden}
            setSchedHidden={setSchedHidden}
          />
        </div>
        <div hidden={alert}>
          <div style={Style.titleStyle}>Generate Link</div>


          <Divider></Divider>

          <Button size="large" style={{ backgroundColor: '#1D82B8' }} block onClick={() => resetCodeDetails()}> Done </Button>
        </div>

        <div hidden={loading} style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
          <Spin size="large" />
        </div>
      </div>
      }
    </div>
  )
}

export default GenerateLink