import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { history } from '../../store/history'
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios'
import notificationServices from '../notification/notificationServices';
import notificationActions from '../notification/notificationActions';
import '../../App.scss';
import AppBarHeader from './headerDiv';
import SideBar from '../sideBar/SideBar';
import { Logout } from '../../layouts/AppBar/appBarComponent/logout';
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';
import { isMobile, isTablet } from 'react-device-detect'
import MobileAppBar from './mobileAppBar/mobileHeader';
import MobileFooter from './mobileAppBar/mobileFooter';
import MicroSurveyModal from '../micro_survey/micro_survey_modal';
import moment from 'moment';
import ThankYouModal from '../micro_survey/thank_you_modal';

const AppBarForm = props => {

  const dispatch = useDispatch()
  const { Header, Content } = Layout;

  const segments = localStorage.getItem("segments");
  const kybStep = localStorage.getItem("KYBStep");
  const approvedStep = [11, 12]
  const testMode = history.location.pathname.indexOf('/test') > -1;
  const userType = localStorage.getItem("userType");
  const subuserRole = localStorage.getItem("subuser_role");
  const kybRequired = localStorage.getItem("kybRequired") === 'true';
  const level = localStorage.getItem("Level");
  const disablePaymentLink =  (
                                (userType === "CO" && localStorage.getItem("KYBStep") < 11) &&
                                (
                                  (!testMode || localStorage.getItem("userType") === "CO") &&
                                  (!approvedStep.includes(parseInt(kybStep)) && !testMode)
                                )
                              )
                              ||
                              ((level <= 0 && userType == 'ME') ||
                              (level <= 0 && userType == 'SW' && subuserRole == 'ME') ||
                              (level != 5 && userType == 'SW' && subuserRole == 'CO' && kybRequired) && !testMode)

  const [isAlias, setIsAlias] = React.useState(true);
  const [canAliasCreatePaymentLink, setCanAliasCreatePaymentLink] = useState(false);
  const isMultiwallet = localStorage.getItem("is_multiwallet") === 'true';

  const theme = useTheme()
  const smallReso = useMediaQuery(theme.breakpoints.down('sm'))
  const mediumReso = useMediaQuery(theme.breakpoints.between('md', 'md'))
  const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;
  const isMac = window.screen.width * window.devicePixelRatio === 1440 && window.screen.height * window.devicePixelRatio === 900;
  const isSmallReso = window.screen.width * window.devicePixelRatio === 1331 && window.screen.height * window.devicePixelRatio === 669;
  const isDell = window.screen.width * window.devicePixelRatio === 1360 && window.screen.height * window.devicePixelRatio === 765;
  const isIpadPro = window.screen.width * window.devicePixelRatio === 1024 && window.screen.height * window.devicePixelRatio === 1366;

  const [surverModal, setSurveyModal] = React.useState(false);
  const [thankYouModal, setThankYouModal] = React.useState(false);

  const startDemo = useSelector(state => state.tutorials.startDemo);

  const getNotifCount = async (params) => {
    try {
     const response = await notificationServices.getNotifications(params);
      dispatch(notificationActions.setNotificationCount(response))
    } catch (error) {
    }
   }

  async function genericAxiosRequest(http_method, target_url, params=undefined) {
    try {
        let response = await axios({
            method: http_method,
            url: process.env.REACT_APP_API_URL + target_url,
            data: params,
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        });
        return response.data;

    } catch (error) {
        if (error.response) {
            if (error.response.status === 401) {
                logout();
            } else {
                throw new Error(error.response.data.message);
            }
        } else {
            throw new Error('No response from backend server!');
        }
    }
  }

  async function checkCanAliasCreatePaymentLink() {
      const setting_name = 'payment_link_creation'
      const endpoint_url = `/api/enterprise/alias/settings/?name=${setting_name}`;

      try {
          let response = await genericAxiosRequest('get', endpoint_url);
          if (response.status === 'failed') {
              throw new Error(response.message);
          }
          setCanAliasCreatePaymentLink(response.message);

      } catch (err) {
          // do nothing
      }
  }

  useEffect(()=>{
    getNotifCount({target: 'count'});

    setTimeout(() => {
        const _alias = localStorage.getItem("is_child") === "true" || localStorage.getItem("isAlias") === 'true';
        setIsAlias(_alias);
        if (_alias) {
          checkCanAliasCreatePaymentLink();
        }
    }, 500);

  },[])

  async function logout() {
    try {
      const yourConfig = {
          headers: {
             Authorization: "Token " + localStorage.getItem("jwtToken")
          }
       }

      let response = await axios.get(
        process.env.REACT_APP_API_URL + "/api/logout/",
        yourConfig
      );

      Logout();
      if(isMobile || smallReso || mediumReso) {
        history.push('/mobile/login')
      }

      else {
        if(testMode && userType === "SW") {
          history.push('/test/login')
        }

        else {
          history.push('/login')
        }
      }
    }
    catch (error) {
      Logout();

      history.push('/login')
    }

  }

  const isLogout = async () => {

    const endpoint_url = `/api/user/satisfaction_survey/?code=${'LOGOUT'}`
    try {
      const res = await genericAxiosRequest('get', endpoint_url);
      if (res && res.status === 'success') {
          if (!res.repeat_date && (['ME', 'CO'].includes(userType))) {
            setSurveyModal(true);
          }
          else if (moment() > moment(res.repeat_date) && (['ME', 'CO'].includes(userType))) {
          // else if (moment().diff(res.repeat_date, 'days') >= -28) {
            setSurveyModal(true);
          } else {
            logout();
          }
      } else {
        logout();
      }
      console.log(res)
    } catch (error) {
        console.error(error);
        logout();
    }
  }

  const onThankYou = () => {
    setSurveyModal(false);
    setThankYouModal(true);
    setTimeout(() => {
      setThankYouModal(false);
      logout();
    }, 5000);
  }

  const [collapsed, setCollapsed] = React.useState(true);
  const [hover, setHover] = React.useState(false)

  function mouseState () {
    setHover(true)
  }

  function exitMouseState() {
    setHover(false)

  }
  const pathname = window.location.pathname;
  const notAdmin = !["CO", "ME", "SW"].includes(userType)

  let pathname_list = [
    '/dashboard',
    '/mobile/card',
    '/orders',
    '/funds_enterprise',
    '/mobile/funds' ,
    '/mobile/payout',
    '/payout_schedule',
    '/mobile/more',
    '/mobile/wallet',
  ]

  const {
    children
  } = props;
  return(
    <div>
      {
        isMobile || isTablet || isIpadPro || smallReso  ?
        <Layout style={{width: '100%', padding: 0, margin: 0, backgroundColor: '#F4F6F9'}}>
          <Header className={'mobilePrimaryHeader'}>
              <MobileAppBar segments={segments} testMode={testMode} logout={isLogout}
              pathname_list={pathname_list} pathname={pathname} />
          </Header>
          <Layout style={{width: '100%', margin: 0, padding: 0, backgroundColor: isMobile && '#F4F6F9'}}>
            <Content style={{marginTop: '1vh 0px', width: '100%'}}>
              {children}
            </Content>
          </Layout>
          <MobileFooter segments={segments} />
        </Layout>
        :

        <Layout style={{width: '100%', backgroundColor: '#F4F6F9'}}>
          <Header className="primaryHeader">
            <AppBarHeader
              userType={userType}
              level={level}
              testMode={testMode}
              isMultiwallet={isMultiwallet}
              disablePaymentLink={disablePaymentLink}
              isAlias={isAlias}
              collapsed={collapsed}offset
              setCollapsed={setCollapsed}
              canAliasCreatePaymentLink={canAliasCreatePaymentLink}
              logout={isLogout}
              segments={segments} />
          </Header>
          <Layout style={{backgroundColor: '#F4F6F9'}}>
            <SideBar collapsed={collapsed} hovered={hover} mouseState={mouseState} exitMouseState={exitMouseState} startDemo={startDemo} />
            <Content style={{
                paddingLeft: "4rem",
                paddingTop: `${notAdmin ? "2rem" : ""}`,
                marginTop:  "64px",
                marginLeft: "80px",
              }}>
              {children}
            </Content>
          </Layout>
        </Layout>
      }
      <MicroSurveyModal visible={surverModal} segments={segments} onClose={() => setSurveyModal(false)} logout={logout} openThanYou={onThankYou} />
      <ThankYouModal visible={thankYouModal} />
    </div>
  )
}
export default AppBarForm
