import React, { useState, useEffect } from "react";
import {
  Pagination,
  Table,
  Card,
  Typography,
  Button,
  Icon,
  Input,
  message,
  DatePicker,
} from "antd";
import { history } from "../../../store/history";
import MerchantCategoriesModal from "./merchant_categories_modal";
import MerchantCategoriesViewModel from "./merchant_categories_viewmodel";
import "./css/merchant_categories.css";
import ClearFilter from "../filter_btns/clear_filter_btn";
import UploadModal from "./upload_modal";
import DialogBoxModal from "./dialog_box_modal";
import moment from "moment";

const MerchantCategories = (props) => {
  const dateFormat = "MM-DD-YYYY";
  const [searchQuery, setSearchQuery] = useState("");
  const [tempSearchQuery, setTempSearchQuery] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isFiltered, setIsFiltered] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [showUploadCSVModal, setShowUploadCSVModal] = useState(false);
  const [showDialogBoxModal, setShowDialogBoxModal] = useState(false);

  const {
    fetchMerchantCategoryList,
    syncFees,
    exportMerchantCategories,
    merchantCategoryData,
    isLoading,
    success,
    messageDisplay,
  } = MerchantCategoriesViewModel();

  function closeModal() {
    setOpenModal(false);
    fetchMerchantCategories(searchQuery, currentPage);
  }

  function onSubmitModal() {
    setOpenModal(false);
    setCurrentPage(1);
    fetchMerchantCategories(searchQuery, 1);
  }

  function openEditModal(record) {
    setEditData(record);
    setOpenModal(true);
  }

  useEffect(() => {
    if (
      localStorage.getItem("jwtToken") == "" ||
      localStorage.getItem("jwtToken") == null
    ) {
      history.push("/login");
    } else {
      if (
        localStorage.getItem("userType") == "ME" ||
        localStorage.getItem("userType") == "CO"
      ) {
        history.push("/dashboard");
      } else if (localStorage.getItem("userType") == "PS") {
        history.push("/orders");
      } else if (localStorage.getItem("userType") == "MA") {
        history.push("/overview");
      } else {
        fetchMerchantCategories("", currentPage);
      }
    }
  }, []);

  useEffect(() => {
    fetchMerchantCategories(searchQuery, currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (messageDisplay != "") {
      if (success) {
        message.success(
          messageDisplay,
        );
      } else {
        message.error(
          messageDisplay
        );
      }
    }
  }, [success, messageDisplay]);

  const columns = [
    {
      title: "User ID",
      dataIndex: "merchant_id",
      fixed: "left",
      render: (text, record) => <span>{record.user.id}</span>,
    },
    {
      title: "User Name and Email",
      width: "15%",
      render: (text, record) => (
        <div className="name-and-email">
          <p>{record.user.merchant_name}</p>
          <p>{record.user.merchant_email}</p>
        </div>
      ),
    },
    {
      title: "Channel",
      render: (text, record) => <span>{record.segment.channel}</span>,
    },
    {
      title: "Category Name",
      render: (text, record) => <span>{record.segment.name}</span>,
    },
    {
      title: "Channel Fee",
      render: (text, record) => <span>{record.segment.channel_fee}</span>,
    },
    {
      title: "Channel Fee Percent",
      render: (text, record) => (
        <span>{record.segment.channel_fee_percent}</span>
      ),
    },
    {
      title: "Date Created",
      dataIndex: "created_at",
    },
    {
      title: "Date Updated",
      dataIndex: "modified_at",
    },
    {
      title: "Action",
      render: (record) => {
        return (
          <a style={{ color: "#0A315E" }} onClick={() => openEditModal(record)}>
            Edit
          </a>
        );
      },
    },
  ];

  const fetchMerchantCategories = (
    searchString,
    page,
    startDateString,
    endDateString
  ) => {
    if (isFiltered || startDateString || endDateString) {
      fetchMerchantCategoryList(
        searchString,
        page,
        startDateString || startDate,
        endDateString || endDate
      );
    } else {
      fetchMerchantCategoryList(searchString, page, "", "");
    }
  };

  const disabledStartDates = (current) => {
    // Cannot select days before today and today
    let isDisabled = current && current > moment().endOf("day");

    if (endDate) {
      // Cannot select days after the end date, if available
      isDisabled =
        isDisabled || current > moment(endDate, dateFormat).startOf("day");
    }

    return isDisabled;
  };

  const disabledEndDates = (current) => {
    // Cannot select days in the future
    let isDisabled = current && current > moment().endOf("day");

    if (startDate) {
      // Cannot select days before the start date, if available
      isDisabled =
        isDisabled || current < moment(startDate, dateFormat).startOf("day");
    }

    return isDisabled;
  };

  return (
    <div
      style={{
        margin: "2em 2.5em 2.5em 2.5em",
        display: "flex",
        gap: "2em",
        flexDirection: "column",
        paddingBottom: "50px",
      }}
    >
      <div>
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            lineHeight: "20px",
            color: "#2B2D33",
          }}
        >
          Merchant Categories
        </Typography>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
        <div className="button-container">
          <Button
            size="large"
            className="secondary-btn"
            onClick={() => {
              setShowDialogBoxModal(true);
            }}
          >
            <Icon type="reload" />
            Sync Fees
          </Button>

          <div style={{ display: "flex", gap: "1em" }}>
            <Button
              size="large"
              type="primary"
              onClick={() => {
                setEditData(null);
                setOpenModal(true);
              }}
              className="primary-btn"
            >
              <Icon type="plus" />
              Add Merchant Category
            </Button>
            <Button
              size="large"
              className="secondary-btn"
              onClick={() => setShowUploadCSVModal(true)}
            >
              <Icon type="upload" />
              Upload CSV
            </Button>
            <Button
              size="large"
              type="primary"
              className="primary-btn"
              onClick={() => exportMerchantCategories(
                searchQuery,
                startDate,
                endDate,
              )}
            >
              <Icon type="download" />
              Export as CSV
            </Button>
          </div>
        </div>
        <Card className="merchant-categories-card">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <ClearFilter
                hideFilterBtn={!isFiltered}
                clearFilter={() => {
                  setSearchQuery("");
                  setTempSearchQuery("");
                  setStartDate("");
                  setEndDate("");
                  setIsFiltered(false);
                  setCurrentPage(1);
                  fetchMerchantCategoryList("", 1, "", "");
                }}
              />

              <div className="merchant-filter-container">
                <DatePicker
                  placeholder="Start Date"
                  disabledDate={disabledStartDates}
                  format={dateFormat}
                  className="date-picker"
                  onChange={(date, dateString) => {
                    if (
                      dateString != "" &&
                      moment(dateString) > moment(endDate)
                    ) {
                      message.error("Invalid start date");
                      setStartDate("");
                    } else {
                      setStartDate(dateString);
                    }
                  }}
                  value={startDate != "" ? moment(startDate) : null}
                />
                <DatePicker
                  placeholder="End Date"
                  disabledDate={disabledEndDates}
                  format={dateFormat}
                  className="date-picker"
                  onChange={(date, dateString) => {
                    if (
                      dateString != "" &&
                      moment(dateString) < moment(startDate)
                    ) {
                      message.error("Invalid end date");
                      setEndDate("");
                    } else {
                      setEndDate(dateString);
                    }
                  }}
                  value={endDate != "" ? moment(endDate) : null}
                />
                <Input
                  placeholder="Search User Name, User Email, Channel, or Category Name"
                  value={tempSearchQuery}
                  onChange={(e) => {
                    setTempSearchQuery(e.target.value);
                  }}
                  suffix={<Icon type="search" />}
                />

                <button
                  className={`admin-apply-btn`}
                  onClick={() => {
                    setCurrentPage(1);
                    fetchMerchantCategories(
                      tempSearchQuery,
                      1,
                      startDate,
                      endDate
                    );
                    setSearchQuery(tempSearchQuery);
                    setIsFiltered(
                      tempSearchQuery !== "" ||
                        startDate !== "" ||
                        endDate !== ""
                    );
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
            <Table
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
              dataSource={merchantCategoryData.data}
              columns={columns}
              pagination={false}
              loading={isLoading}
              scroll={{ x: true }}
            />

            <Pagination
              size="small"
              total={(merchantCategoryData && merchantCategoryData.count) || 0}
              defaultPageSize={pageSize}
              defaultCurrent={1}
              current={currentPage}
              onChange={setCurrentPage}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              style={{ paddingTop: "12px", textAlign: "right" }}
            />
          </div>
        </Card>
      </div>

      <MerchantCategoriesModal
        editData={editData}
        openModal={openModal}
        closeModal={closeModal}
        submitModal={onSubmitModal}
      />

      <UploadModal
        handleRefresh={() => fetchMerchantCategories(searchQuery, currentPage)}
        handleClose={() => {
          fetchMerchantCategories(searchQuery, currentPage);
          setShowUploadCSVModal(false);
        }}
        modal={showUploadCSVModal}
        visible={showUploadCSVModal}
        onUploadEndpoint={""}
      />

      <DialogBoxModal
        closeModal={() => {
          fetchMerchantCategories(searchQuery, currentPage);
          setShowDialogBoxModal(false);
        }}
        onSubmit={() => {
          if (isFiltered) {
            syncFees(searchQuery, startDate, endDate);
          } else {
            syncFees("", "", "");
          }

          setShowDialogBoxModal(false);
        }}
        openModal={showDialogBoxModal}
      />
    </div>
  );
};

export default MerchantCategories;
