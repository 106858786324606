import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Tabs, Tab } from '@material-ui/core'
import { Card, Radio, Spin, Button, Modal, Typography } from 'antd'
import { getDashboardNow, dashboardActions } from '../../dashboard/dashboardAction';
import { history } from '../../../store/history';
import { AreaChart, XAxis, YAxis, Tooltip, CartesianGrid, Area, ResponsiveContainer } from 'recharts';
import { isMobile } from 'react-device-detect';
import * as Style from '../../complete_profile/complete_profile_style'
import MetaTag from '../../meta_tag/meta_tag'
import MobileBasicCard from '../../cards/mobile/basic_modal';
import MobileAccountCard from '../../cards/mobile/acccount_card';
import BankAdvisoryCard from "../../cards/mobile/bank_advisory_card";
import { Handle401 } from '../../handle401/handle401';
import moment from 'moment';
import AccountLimitsTracker from '../../dashboard/account_limits_tracker/account_limits_tracker';
import AccountLimitCorporate from '../../dashboard/account_limits_tracker/account_limit_corpo'
import { getDashboardGraphData } from "../../dashboard/dashboardServices";
import DashboardSidebar from '../../dashboard/dashboard_sidebar';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import OrderSalesCard from '../../dashboard/order_sales_card/order_sales_card';
import MarketPromotion from '../../dashboard/marketing_promotion/market_promotions'
import expiryStyle from '../../../static/css/Expiry';
import { PasswordChange } from '../../../static/icons/index';
import { randomize } from '../../constants/constants'
import WhatsNewQr from '../../account_settings/bux_qr/whats_new_modal';
import MyCheckoutCard from "../../dashboard/my_checkout/my_checkout_card";
import { DBMultiWalletCard } from '../../multiwallet';


const MobileDashboard = props => {

    const noTradeName = localStorage.getItem("tradeName") == ""

    const dispatch = useDispatch()
    const es = expiryStyle();

    // const dashboard = useSelector(state => state.dashboard.dashboard.week)
    const endpoints = useSelector(state => state.child_endpoints.endpoints);
    const [limitModal, setLimitModal] = React.useState(false)
    // const datas = useSelector(state => state.dashboard.dashboard.data)
    const [balance, setBalance] = React.useState("0.00")
    const [buxbalance, setBuxBalance] = React.useState("0.00")
    const [expiryDate, setExpiryDate] = React.useState("")
    const [cardNumber, setCardNumber] = React.useState(null)
    const [lockCard, setLockCard] = React.useState(null)
    const allData = useSelector(state => state.dashboard.dashboard)
    // const graphData = useSelector(state => state.dashboard.dashboardGraph);
    const [graphData, setGraphData] = React.useState(null);
    const [buttonClicked, setButtonClicked] = React.useState('1w');
    const [interval, setInterval] = React.useState(2);
    const [tab, setTab] = React.useState(0);
    const [noEon, setNoEon] = React.useState(null);
    const [custId, setCustId] = React.useState(null);
    const [showLoginModal, setShowLoginModal] = React.useState(!localStorage.getItem('isWaive') ? false : true)
    const [dashboard, setDashboard] = React.useState(null);
    const [datas, setDatas] = React.useState(null);
    const [showWhatsNew, setShowWhatsNew] = React.useState(localStorage.getItem('tradeName') || localStorage.getItem('Level') == 0  ? false : true)
    const isChild = localStorage.getItem("is_child") === "true";
    const userType =localStorage.getItem('userType');
    const kybStep = localStorage.getItem('KYBStep');
    const level = localStorage.getItem("Level");
    const step = localStorage.getItem("Step");
    const isCardRejected = (userType == "CO" && kybStep == 10) || (level == "0" && step == 9) || (level == "1" && step == 14) || (level == "2" && step == 19);


    const modalMessage = 
    localStorage.getItem('isWaive') == 1 ?
        `
        You have waived changing your password. 
        We will remind you to update your password again on ${localStorage.getItem('expiryDate')}. You can also change your password through Settings > My Profile > Password > Change.
        `
        :

        `
        Your password has been changed successfully. 
        You can also change your password anytime through Settings > My Profile > Password > Change.    
        `
    const graphButtons = ['1w', '4w', '1y', 'mtd', 'ytd', 'all'];

    const handleClick = (event) => {
        // dispatch(dashboardActions.setData(null));

        if(event.target.value == '1w' ){
            setInterval(2);  
        }else if(event.target.value == 'mtd' || event.target.value == '4w'){
            setInterval(7);  
        }
        else if(event.target.value == 'ytd' || event.target.value == '1y' || event.target.value == 'all'){
            setInterval(90); 
        }
        else{
            setInterval(90);  
        }

        setButtonClicked(event.target.value);
        getGraphData(event.target.value);
    }

    const getGraphData = async (event) => {
        try {
          const response = await getDashboardGraphData(event);
          
          let graphDataConverted = [];
          response && response.map((item) => {
              graphDataConverted.push({
                  name: item.name,
                  amount: parseFloat(item.amount),
              })
          })
          console.log('GRAPHDATAS',response);
          setGraphData(graphDataConverted);
          dispatch(dashboardActions.setData(graphDataConverted));
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
      };

    const getCardData = async () => {
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        try {

            let response = await axios.get(
                process.env.REACT_APP_API_URL + '/api/dashboard/', yourConfig
            );
            setBuxBalance(response.data.bux_balance)
            setDashboard(response.data.week);
            setDatas(response.data);
            if(response.data.card.lastFourDigits){
                setNoEon(response.data.card.lastFourDigits);
                setBalance(response.data.card.account.availableBalance);
                setCardNumber(response.data.card.lastFourDigits);
                setExpiryDate(response.data.card.expiryDate);
                setLockCard(response.data.card.status == '1' ? false : true);
                setCustId(response.data.card.customer_id);

            }
        }

        catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }

        }
    }

    useEffect(() => {
        let current_path = window.location.pathname
        if (localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null) {
            history.push('/messenger?next=' + current_path)
        }
        else {

            if (isMobile) {
                history.push('/dashboard')
            }
            else if (["AD", "OP", "KB", "FI", "BS", "AP", "EN"].includes(localStorage.getItem("userType"))
                     || (localStorage.getItem("userType") === "CO" && localStorage.getItem("merchant_bias") === "true")) {
                history.push('/orders')
            }
            else if (localStorage.getItem("userType") == "PS" || localStorage.getItem("userType") == "PF") {
                history.push('/submissions_kyb')
            }
            else if (localStorage.getItem("userType") == "MA") {
                history.push('/overview')
            }
            else if (localStorage.getItem("userType") == "KC") {
                history.push('/submissions')
            }
            else if (localStorage.getItem("userType") == "SD") {
                history.push('/submissions_kyb')
            }
            else if (localStorage.getItem("userType") == 'SW'){
                history.push('/mobile/more')
            }
            else {
                // dispatch(getDashboardNow());
                getCardData();
                getGraphData(buttonClicked);

            }
        }
    }, []);


    useEffect(() => {
        if (!isChild || !endpoints.length) {
            return;
        }

        // Redirect if URL was manually typed
        if(isMobile && !endpoints.includes('dashboard')) {
            history.push(endpoints[0]);
        }
    }, [endpoints])


    let week = (dashboard ? dashboard.data : [])

    let data = [];
    week.map((item) => {
        data.push({
            name: item[0],
            amount: item[1],
        })
    })
    let lastEntry = (data ? data[data.length - 1] : '')

    const headerStyle = {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '32px',
        color: '#000000'
    }

    const textStyle = {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '24px',
        color: '#000000',
        marginBottom: '20px'
    }

    const labelStyle = {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#000000',
    }

    const cardStyle = {
        gridTemplateColumns: '100%',
        padding: '10px',
        margin: '16px 0 56px 0',
        width: '100%',
        height: '180',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)'
    }

    const LoginModal = () => {
        return(
            <Modal
                centered
                width={400}
                visible={showLoginModal}
                onCancel={() => done()}
                footer={null}>
                    <div>
                        <div align="center" className={es.inputDiv}>
                            <img src={PasswordChange} alt="Icon" />
                            <div className={es.successText}>
                                Success!
                            </div>
                        </div>

                        <div className={es.message1} style={{color: '#000000'}}>
                            <p>
                            {modalMessage}
                            </p>
                        </div>

                        <div>
                            <button 
                                className={es.updatePassword}
                                onClick={() => done()}
                            >
                                Done
                            </button>
                        </div>
                    </div>
            </Modal>
        )
    }

    function done(){
        setShowLoginModal(false)
        localStorage.removeItem('isWaive')
    }
    //     <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
    // <Radio.Group defaultValue="Daily" buttonStyle="solid">
    //     <Radio.Button value="Daily">Daily</Radio.Button>
    //     <Radio.Button value="Weekly">Weekly</Radio.Button>
    //     <Radio.Button value="Monthly">Monthly</Radio.Button>
    // </Radio.Group>
    // </div>
    // const {TabPane} = Tabs;

    const SalesGraphButton = () => {
        return (
            <div style={{display: 'flex', marginBottom: 20, width: '100%'}}>
                <Button.Group style={{width: '100%', fontSize: 16}}>
                    {graphButtons.map((item, index) => {
                        return (
                            <Button key={item} style={{
                                height: 48,
                                width: '16.7%',
                                backgroundColor: `${buttonClicked === item ? '#273C75' : '#FFFFFF'}`,
                                color: `${buttonClicked === item ? '#FFFFFF' : '#000000'}`,
                                borderRadius: index == 0 ? '10px 0 0 10px' : index == 5 ? '0 10px 10px 0' : 0,
                            }}
                                value={item}
                                onClick={handleClick}
                            >{  item === 'mtd' ? item.charAt(0).toUpperCase() + item.substring(1) :
                                item === 'ytd' ? item.charAt(0).toUpperCase() + item.substring(1) :
                                item === 'all' ? item.charAt(0).toUpperCase() + item.substring(1) :
                                item }</Button>
                        )
                    })}
                </Button.Group>
            </div>
        )    
    }

    const Dashboard = () => {
        // If endpoints have not been loaded yet for mobile view. Hook on endpoints will handle redirect
        if (isChild && isMobile && !endpoints.includes('dashboard')) {
            return null;
        }

        return (
            <div>
            <BankAdvisoryCard />
            <WhatsNewQr showModal={showWhatsNew} closeModal={setShowWhatsNew} />
            {LoginModal()}

            {    
                localStorage.getItem('Step') == 16 || localStorage.getItem('KYBStep') >= 12 || datas && moment().diff(moment(datas.application_submission_date), 'days') > 1 && !isCardRejected ?

                    <div hidden={userType === "SW"}><MobileBasicCard /></div> :

                localStorage.getItem('Level') == "0" && localStorage.getItem('Step') >= 6 ?
                
                    <div hidden={userType === "SW"}><MobileAccountCard /></div>
                
                // localStorage.getItem('Level') == "0" || localStorage.getItem('Step') <= 6 ?    

                //     <div><MobileAccountCard /></div> 
                    
                : <div hidden={userType === "SW"}><MobileAccountCard /></div>

            }
            {/* <Card size="small">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '5px' }}>
                    <div style={labelStyle}><strong>Today's Sales</strong></div>
                    <div style={{ color: '#2B2D33', fontSize: 32, display: 'flex', alignItems: 'center'}}>
                        <Typography style={{fontSize: 18, padding: '0 10px'}}>PHP</Typography>
                        <strong>{lastEntry ? `${lastEntry.amount.toFixed(2)}` : <Spin size="small" />}</strong>
                    </div>
                </div>
            </Card>
            <Card size="small">
                <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px' }}>
                    <div style={labelStyle}><label style={{ color: '#2B2D33' }}>Total Paid</label></div>
                    <div style={labelStyle}><strong style={{ fontSize: 24, color: '#1DD28B' }}>{datas ? datas.paid : <Spin size="small" />}</strong></div>
                </div>
            </Card>
            <Card size="small">
                <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px' }}>
                    <div style={labelStyle}><label style={{ color: '#2B2D33' }}>Total Pending</label></div>
                    <div style={labelStyle}><strong style={{ fontSize: 24, color: '#F5922F' }}>{datas ? datas.pending : <Spin size="small" />}</strong></div>
                </div>
            </Card>
            <Card size="small">
                <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px' }}>
                    <div style={labelStyle}><label style={{ color: '#2B2D33' }}>Total Payment Expired</label></div>
                    <div style={labelStyle}><strong style={{ fontSize: 24, color: '#E24C4C' }}>{datas ? datas.expired : <Spin size="small" />}</strong></div>
                </div>
            </Card>
            <Card size="small" style={{ marginBottom: '20px' }}>
                <div style={{ display: 'flex', justifyContent: 'center', margin: '4px' }}>
                    <div style={labelStyle}><a onClick={() => history.push('/orders')} style={{ fontSize: 14, fontWeight: '600', textDecoration: 'none', color: '#F5922F' }} >VIEW ORDERS</a></div>
                </div>
            </Card> */}

            <OrderSalesCard data={{ datas, lastEntry }} />
            {
                localStorage.getItem("userType") == "CO" && localStorage.getItem("KYBStep") < 11 ?
                    <AccountLimitCorporate />
                    :
                    <AccountLimitsTracker data={datas? datas : []} />
            }

            <div style={{marginTop: '20px'}} hidden={localStorage.getItem("userType") == "SW"}>
                <MyCheckoutCard/>
            </div>

            <DBMultiWalletCard />

            <Card style={cardStyle} bodyStyle={{padding: 12}}>
                <div style={{   marginBottom: 10,
                                fontSize: 16, 
                                fontWeight: '600', 
                                textAlign: 'left', 
                                color: '#2B2D33',
                            }}>Sales in {moment().format('YYYY')}</div>
                <SalesGraphButton />
                {graphData ?
                    <ResponsiveContainer height={200} width={300}>
                        <AreaChart
                            style={{ marginLeft: '-30px' }}
                            data={graphData}
                        >
                            <CartesianGrid vertical={false} horizontal horizontalFill={['#FEFEFE']} fillOpacity={0.1} />
                            <XAxis dataKey="name" key={randomize()} axisLine={{ stroke: '#FFFFFF' }} tickLine={false} interval={interval} />
                            <YAxis axisLine={{ stroke: '#FFFFFF' }} tickLine={false} />
                            <Tooltip />
                            <Area type="monotone" dataKey="amount" stroke="#8884d8" />
                        </AreaChart>
                    </ResponsiveContainer>
                    :

                    <div style={{ width: '100%', textAlign: 'center', height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Spin size="large" /></div>
                }
            </Card>

            
            <div hidden={localStorage.getItem("userType") == "SW"}>
                 <MarketPromotion />
             </div>
           
            
            <Modal
                visible={limitModal}
                onCancel={() => setLimitModal(false)}
                footer={null}
            >
                <div style={Style.titleStyle}>Limits by Level</div>
                <div style={Style.tableLimits}>
                    <Grid container style={Style.tlHeader}>
                        <Grid xs={4}>
                            <div>Level</div>
                        </Grid>

                        <Grid xs={4}>
                            <div>Cash in</div>
                        </Grid>

                        <Grid xs={4}>
                            <div>Cash out</div>
                        </Grid>
                    </Grid>

                    <Grid container style={Style.tlText}>
                        <Grid xs={4}>
                            <div><b>Basic</b></div>
                        </Grid>

                        <Grid xs={4}>
                            <div>PHP 2,000</div>
                        </Grid>

                        <Grid xs={4}>
                            <div>PHP 0</div>
                        </Grid>
                    </Grid>

                    <Grid container style={Style.tlText}>
                        <Grid xs={4}>
                            <div><b>Pro</b></div>
                        </Grid>

                        <Grid xs={4}>
                            <div>PHP 50,000</div>
                        </Grid>

                        <Grid xs={4}>
                            <div>PHP 50,000</div>
                        </Grid>
                    </Grid>

                    <Grid container style={Style.tlText}>
                        <Grid xs={4}>
                            <div><b>Business</b></div>
                        </Grid>

                        <Grid xs={4}>
                            <div>PHP 400,000</div>
                        </Grid>

                        <Grid xs={4}>
                            <div>PHP 400,000</div>
                        </Grid>
                    </Grid>

                    <Grid container style={Style.tlText}>
                        <Grid xs={4}>
                            <div><b>Enterprise</b></div>
                        </Grid>

                        <Grid xs={4}>
                            <div>UNLIMITED</div>
                        </Grid>

                        <Grid xs={4}>
                            <div>UNLIMITED</div>
                        </Grid>
                    </Grid>
                </div>
            </Modal>
            </div>
        )
    }


    const useStyles = makeStyles({
        root: {
          flexGrow: 1,
          background: '#F4F6F9',
        //   position: 'fixed',
          width: '100%',
          zIndex: 100,
        //   marginTop: '-1px'
        },
        indicator: {
            backgroundColor: '#F5922F',
            height: 4,
        },
    });

    const classes = useStyles();
    return (
        <div>
            <div className={classes.root}>
                <div style={{ padding: '1px 16px 16px 16px' }}>
                    <MetaTag title="Dashboard" />
                    <Dashboard />
                </div>
            </div>
        </div>
    )
}

export default MobileDashboard