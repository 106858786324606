import React, { useState, useEffect } from "react";
import { Card, Typography, Spin, Button, Tag, message, Modal } from "antd";
import TrackerBody from './tracker_body';
import { Cell } from "recharts";
import Questionnaire from '../../questionnaire/questionnaire';
import { history } from "../../../store/history";
import { isMobile } from "react-device-detect";
import { database } from "faker/locale/cz";
import axios from 'axios';
import { Handle401 } from "../../handle401/handle401";

const AccountLimitsTracker = (props) => {
  const { data } = props;
  const cashIn = data.cashin_limits ? data.cashin_limits : [];
  const cashOut = data.cashout_limits ? data.cashout_limits : [];
  const totalPaid = data.paid;
  const level = localStorage.getItem("Level");
  const kycStep = localStorage.getItem("Step");
  const kybStep = localStorage.getItem("KYBStep");
  const [trackerValue, setTrackerValue] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [btnClicked, setBtnClicked] = useState('monthly');
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [showConvertConfirmModal, setShowConvertConfirmModal] = React.useState(false)
  const userType = localStorage.getItem('userType');
  const [showModal, setModal] = React.useState(false)
  const testAccess = ['CO', 'SW']
  const testMode = (history.location.pathname.indexOf('/test') > -1 && testAccess.includes(userType));
  const segments = localStorage.getItem('segments') ? localStorage.getItem('segments') == 'nil' ? 'me_' : localStorage.getItem('segments') : 'me_';
  const userId = localStorage.getItem('userId');
  const [currenSegment, setCurrentSegment] = React.useState(undefined);
  const [upgradeToSegment, setUpgradeToSegment] = React.useState(undefined);

  let meterData = {
    daily:
      [{
        name: 'Cash In',
        current: 0,
        limit: 0,
      },
      {
        name: 'Cash Out',
        current: 0,
        limit: 0,
      },],
    monthly: [
      {
        name: 'Cash In',
        current: cashIn ? cashIn[0] : 0,
        limit: cashIn ? cashIn[1] : 0,
      },
      {
        name: 'Cash Out',
        current: cashOut ? cashOut[0] : 0,
        limit: cashOut ? cashOut[1] : 0,
      }],
    annually: [
      {
        name: 'Cash In',
        current: 0,
        limit: 0,
      },
      {
        name: 'Cash Out',
        current: 0,
        limit: 0,
      }],
  }

  let upgradeButtonInfo = [
    {
      segment: 'me_',
      type: 'BUxME',
      requiredTxn: 20,
      isDisabled: (totalPaid < 20 || kycStep < 10) ? true : false,
      upgradeToText: 'Upgrade to BUxGIG',
    },
    {
      segment: 'gig',
      type: 'BUxGIG',
      requiredTxn: 20,
      isDisabled: (totalPaid < 20 || kycStep < 10) ? true : false,
      upgradeToText: 'Upgrade to BUxSME or BUxBIZ',
    },
    {
      segment: 'sme',
      type: 'BUxSME',
      requiredTxn: 100,
      isDisabled: kybStep < 11 ? true : false,
      upgradeToText: 'Upgrade to BUxBIZ',
    },
    {
      segment: 'biz',
      type: 'BUxBIZ',
      requiredTxn: 100,
      isDisabled: false,
      upgradeToText: 'View History',
    },
  ];

  const setType = (type) => {
    switch (type) {
      case 'me_':
        return 0
      case 'gig':
        return 1
      case 'sme':
        return 2
      case 'biz':
        return 3
      default:
        return 0
    }
  }

  const ConvertConfirmationModal = () => {
    return (
      <Modal
        width={360}
        title={"Convert User"}
        visible={showConvertConfirmModal}
        onCancel={() => closeConfirmationModal()}
        footer={[
          <button key="back"
            onClick={() => closeConfirmationModal()}
            className={`outline-btn--${segments}`}>
            Cancel
          </button>,
          <button key="submit" type="primary"
            onClick={() => onUpgradeAccount()}
            className={`btn--${segments}`}>
            Proceed
          </button>,
        ]}>
        <div style={{ fontSize: '16px', textAlign: 'left', padding: '10px 0px' }}>
          Are you sure you want to upgrade your account level from {currenSegment} to {upgradeToSegment}?
        </div>
      </Modal>
    )
  }

  const closeConfirmationModal = () => {
    setShowConvertConfirmModal(false);
  }

  const UpgradeButton = () => {
    try{
      if (upgradeButtonInfo.find(obj => obj.segment === segments).isDisabled && segments !== 'sme') {
        return (
          <div>
            <span style={{ fontWeight: 'bold' }}>
              {upgradeButtonInfo.find(obj => obj.segment === segments).requiredTxn}&nbsp;
            </span>
            <span>Paid transactions to {segments !== 'biz' ? 'upgrade' : 'convert'}</span>
          </div>
        )
      }
      else if (upgradeButtonInfo.find(obj => obj.segment === segments).isDisabled && segments === 'sme') {
        return (
          <div>
            <span>Needs approved KYB to upgrade</span>
          </div>
        )
      }
    }catch(error){}

    return (
      <Button type="link"
        className={`smallBoldStyle text-${segments}`}
        onClick={segments === 'biz' ? () => onViewHistory() : () => onClickUpgradeAccountButton()}>
        {upgradeButtonInfo.find(obj => obj.segment === segments) ? upgradeButtonInfo.find(obj => obj.segment === segments).upgradeToText : 'Upgrade to BUxGIG' }
      </Button>
    )
  }


  if (!initialized && (typeof (cashIn[0]) !== 'undefined')) {
    setTrackerValue(meterData.monthly)
    setInitialized(true);
  }

  const handleClick = (event) => {
    setBtnClicked(event.target.value);
    setIsButtonLoading(true);
    setTimeout(() => {
      setIsButtonLoading(false);
    }, 300);
    if (event.target.value === 'daily') {
      setTrackerValue(meterData.daily);
    } else if (event.target.value === 'monthly') {
      setTrackerValue(meterData.monthly);
    } else if (event.target.value === 'annually') {
      setTrackerValue(meterData.annually);
    }
  }

  function openModal(val) {
    setModal(val)
  }

  const onViewHistory = () => {
    return isMobile ? window.location.href = '/orders' : history.push(testMode ? '/test/orders/' : '/orders')
  }

  const onClickUpgradeAccountButton = () => {
    if (segments === 'biz' || segments === 'gig') {
      history.push('my_profile');
    } else {
      setShowConvertConfirmModal(true)
    }
  }

  const onUpgradeAccount = async () => {
    let params = {
      userId: userId,
    }

    let yourConfig = {
      headers: {
        Authorization: "Token " + localStorage.getItem("jwtToken")
      }
    }

    try {
      const url = '/api/user/convert/';
      let response = await axios.post(
        process.env.REACT_APP_API_URL + url, params, yourConfig
      );

      if (response.data.status === "success") {
        window.location.reload(true);
      }

      else {
        message.info(response.data.message, 3)
      }
      //setDatas(response.data)
      //setDashboard(response.data.week)
      //setShowConvertWelcomeModal(response.data.show_conversion_welcome)
    }

    catch (error) {
      if ((error.response) && (error.response.status === 401)) {
        Handle401()
      }

    }
  }

  React.useEffect(() => {
    setCurrentSegment(upgradeButtonInfo[Object.keys(upgradeButtonInfo)[setType(segments)]].type);
    if (segments !== 'biz') {
      setUpgradeToSegment(upgradeButtonInfo[Object.keys(upgradeButtonInfo)[setType(segments) + 1]].type)
    }
  }, [])

  return (
    <Card 
      className="tracker-card" 
      bodyStyle={{ padding: '12px 16px', }}
      size="small"
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography className="boldTextStyle">Account Limits</Typography>
      </div>

      {
        data.cashin_limits && !isButtonLoading ?
          <TrackerBody id='trackerBody' data={trackerValue} />
          :
          data.cashin_limits && isButtonLoading ?
            <Spin size="large" className="spinner" />
            :
            <Spin size="default" className="spinner" />
      }

      {
        (userType === 'ME' || userType === 'CO') &&
        <div align="center">
          <UpgradeButton />
        </div>
      }


      {/* <div 
        hidden={
          localStorage.getItem('Level') == 5 || 
          userType === "SW" } 
        style={{
          textAlign: 'center', 
          padding: level == 2 && data.paid > 100 || 
          level == 1 && data.paid > 20 || 
          level == 1 && data.paid <= 20 || 
          level == 2 && data.paid <= 100 ? '7px 0px' : 0}}>

        <div hidden={level == 5 || level == 3 || (level == 1 && data.paid >= 20) || level == 0 || (level == 2 && data.paid >= 100)} className="smallTextStyle disable-color" >
          At least {level == 1?<b>20 PAID</b> : <b>100 PAID</b>} transactions to upgrade
        </div>
        <Button type="link" 
          hidden={
            level == 5 || 
            level == 3 || 
            (level == 1 && data.paid < 20) || 
            level == 0 || 
            (level == 2 && data.paid < 100)
          }
          className={
            (level == 1 && (data.paid < 20 || (data.application_cooldown && data.application_cooldown/60/60/24 < 30))) || 
            (level == 2 && (data.paid < 100 || (data.application_cooldown && data.application_cooldown/60/60/24 < 30))) ? "footer-disable": `smallBoldStyle text-${segments}`
          } 
          disabled={(level == 1 && (data.paid < 20 || (data.application_cooldown && data.application_cooldown/60/60/24 < 30))) || (level == 2 && (data.paid < 100 || (data.application_cooldown && data.application_cooldown/60/60/24 < 30)))} 
          onClick={
            (level == "0" && (step == 8 || step == 7)) || (level == "1" && (step == 12 || step == 13))
            ? () => window.location.href = isMobile ? '/mobile/upgrade/' : '/upgrade'
            : (level == "0")
              ? () => window.location.href = '/complete_profile/'
              : (level == "0" && step == 9)
                ? () => window.location.href = '/complete_profile/'
                : (level == 1 && data.paid >= 20 && (!data.application_cooldown || (data.application_cooldown && data.application_cooldown/60/60/24 >= 30)))
                  ? () => openModal(true)
                  :(level == 2 && data.paid >= 100 && (!data.application_cooldown || (data.application_cooldown && data.application_cooldown/60/60/24 >= 30)))
                  ? () => openModal(true)
                  : null
        }>Upgrade Account</Button>
      </div> */}
      <Questionnaire open={showModal} onClose={() => setModal(false)} />
      <ConvertConfirmationModal />
    </Card>
  )
}

export default AccountLimitsTracker;