import React from 'react'
import { BUx } from '../../static/icons/bux_qr'
import QRPHImageStyles from './styles.module.css'

const QRPHImage = props => {    
    const {
        staticQRPHImageURL,
        displayedName,
        setQRPHImageIsRendering,
        setQRPHImageOpacity,
        isStaticQRPH,
        amount,
        notes,
    } = props


    const handleStaticQRPHOnLoad = () => {
        setQRPHImageIsRendering(false);
        setQRPHImageOpacity(1);
    }

    return (
        <>
            {
                isStaticQRPH &&
                <div 
                    className={QRPHImageStyles.buxImageStatic}
                    style={{
                        fontSize: "clamp(10.59px, 3.96vw, 16px)",
                    }}
                >
                    <div className={QRPHImageStyles.mainContent}>        
                        <img
                            className={QRPHImageStyles.qrphImage}
                            src={staticQRPHImageURL}
                            onLoad={handleStaticQRPHOnLoad}
                            alt="QRPH QR code"
                            style={
                                isStaticQRPH ? 
                                {
                                    maxWidth: "250px",
                                } :
                                ""
                            }
                        />
                        <div className={QRPHImageStyles.tradeNameStatic}>
                            {displayedName}
                        </div>
                        <div className={QRPHImageStyles.footer}>
                            {
                                amount && (
                                    <div className={QRPHImageStyles.amount}>
                                        ₱ {amount.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                                    </div>
                                )
                            }
        
                            {
                                notes &&
                                notes.length &&
                                <div className={QRPHImageStyles.notes}>
                                {
                                    notes.map((note, index) => (
                                        <div key={index}>
                                            {note}
                                        </div>
                                    ))
                                }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
            {
                !isStaticQRPH &&
                <div 
                    className={QRPHImageStyles.buxImage}
                    style={
                        isStaticQRPH ? 
                        {
                            fontSize: "clamp(10.59px, 3.96vw, 16px)",
                        } :
                        {
                            fontSize: "clamp(11.84px, 3.02vw, 16px)",
                        }
                    }
                >
                    <img 
                        src={BUx}
                        className={QRPHImageStyles.buxLogo}
                        alt="BUx logo"
                    />
        
                    <div className={QRPHImageStyles.mainContent}>
                        <div className={QRPHImageStyles.tradeNameWrapper}>
                            <div className={QRPHImageStyles.tradeName}>
                                {displayedName}
                            </div>
                        </div>
        
                        <img
                            className={QRPHImageStyles.qrphImage}
                            src={staticQRPHImageURL}
                            onLoad={handleStaticQRPHOnLoad}
                            alt="QRPH QR code"
                        />
        
                        <div className={QRPHImageStyles.footer}>
                            {
                                amount && (
                                    <div className={QRPHImageStyles.amount}>
                                        ₱ {amount.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                                    </div>
                                )
                            }
        
                            {
                                notes &&
                                notes.length &&
                                <div className={QRPHImageStyles.notes}>
                                {
                                    notes.map((note, index) => (
                                        <div key={index}>
                                            {note}
                                        </div>
                                    ))
                                }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default QRPHImage;