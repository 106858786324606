import React, { useState, useEffect } from 'react';
import OTPInput from "otp-input-react";
import multiwalletServices from '../../multiwalletServices';
import { Card, Typography, Modal, Button, Spin, Affix, Icon, Divider, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import CancelIcon from '@material-ui/icons/Cancel';
import { history } from '../../../../store/history';

const OtpModal = (props) => {
  const { visible, closeModal, resetTimer, setResetTimer, otpUid, onSuccess, onResend, otpSubj, otpData, isEmailOtp, onEmailOtp } = props;
  const [OTP, setOTP] = useState('');
  const [counter, setCounter] = useState(0);
  // const resetTimer = useSelector(state => state.card.otpTimerReset)
  const dispatch = useDispatch();
  const [btnStyle, setBtnStyle] = useState({ backgroundColor: 'transparent', color: '#F5922F' })
  const [notifMsg, setNotifMsg] = useState(null);
  const [isError, setIsError] = useState(false);
  const [resBtnLoading, setResBtnLoading] = useState(false);
  const [subBtnLoading, setSubBtnLoading] = useState(false);
  const [emailBtnLoading, setEmailBtnLoading] = useState(false);
  const [emailOtp, setEmailOtp] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const attemptError = useSelector(state => state.card['attempt_error']);
  const attemptErrorMsg = useSelector(state => state.card.attemptErrorMsg);
  const testMode = (history.location.pathname.indexOf('/test') > -1);
  const segments = localStorage.getItem('segments');

  const handleChange = (code) => {
    setOTP(code)
  }

  if (resetTimer) {
    setCounter(30);
    setResBtnLoading(false);
    setBtnStyle({
      backgroundColor: 'transparent',
      color: '#F5922F'
    });
    setEmailOtp(false);
    setOTP('');
    setResetTimer();
  }

  if (isError || isSuccess) {
    setTimeout(() => {
      setIsError(false)
      setIsSuccess(false)
    }, 5000);
  }

  const submitOTP = async () => {
    setSubBtnLoading(true)
    let params = isEmailOtp ?
      { code: OTP } :
      {
        uid: otpData.uid,
        code: OTP,
        spin: true,
        otp_type: 1
      };
    let response = {};  
    try {
      response = await multiwalletServices.submitOtp(params);

      console.log(response)
      // if (response.status != 200) {
      //   throw Error;
      // }
      if (response.status === 'success') {
        closeModal();
        setSubBtnLoading(false)
        setOTP('')
        onSuccess(response.trans_uid);
      } else if (response.status === 'failed') {
        message.error(response.message);
        setSubBtnLoading(false);
      }
    } catch (error) {
      console.log(error);
      setSubBtnLoading(false);
      if(response.code === 'attempts'){
        setNotifMsg(response.message);
        setCounter(0);
      } else {
        setIsError(true);
        try{
          setNotifMsg(response.message);
        } catch (error){
          setNotifMsg('Something went wrong.')
        }
      }
    }
  }

  const handleResend = () => {
    setResBtnLoading(true)
    onResend();
  }

  const handleMouseOver = () => {
    setBtnStyle({
      backgroundColor: '#F5922F',
      color: '#FAFAFA'
    });
  }

  const handleMouseLeave = () => {
    setBtnStyle({
      backgroundColor: 'transparent',
      color: '#F5922F'
    });
  }

  const calculateDuration = (duration) => {
    const minutes = Math.floor(duration/60).toString();
    const seconds = Math.floor(duration%60);
    let seconds_str = seconds.toString();

    if(seconds < 10){
        seconds_str = "0" +seconds.toString();
    }

    return minutes + ":" + seconds_str
};

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter])

  const onClose = () => {
    closeModal()
    setSubBtnLoading(false);
    setCounter(0);
    setEmailOtp(false);
    setOTP('');
  }

  React.useEffect(() => {
    setCounter(30);
    setResBtnLoading(false);
    setBtnStyle({
      backgroundColor: 'transparent',
      color: '#F5922F'
    });
    setEmailOtp(false);
    setOTP('');
  }, [otpData])
  console.log('ISEMAIL_OTP', isEmailOtp);
  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={() => onClose()}
      centered
      maskClosable={false}
      width={456}
      bodyStyle={{ padding: 24 }}
      closeIcon={<Icon type="close" className={`link-text--${segments}`} />}
    >
      {isSuccess ?
        <Affix offsetTop={20} style={styles.affix}>
          <div
            style={{
              ...styles.notif,
              backgroundColor: isError ? '#FFF' : '#1DD28B',
              color: isError ? '#E24C4C' : '#FFFFFF',
            }}
          >
            {isError ? <CancelIcon
              style={{
                margin: '0 10px 0 0'
              }}
            /> : null}
            <div>
              <div align='left'>{notifMsg}</div>
            </div>
          </div>
        </Affix>
        : null
      }
      <div style={styles.body}>
        <Typography style={styles.subtitle}>One-Time PIN</Typography>
        {isEmailOtp ?
          <Typography style={styles.normalFont}>
            You will receive a One-Time Password (OTP) on your registered email address.
          </Typography>
          : <Typography style={styles.normalFont}>You will receive a One-Time Password (OTP) on your registered mobile number{attemptError ? null : ' ending in '}
            <strong>{otpData && otpData.mobile.replace(/.(?=.{4})/g, '')}</strong>.
          </Typography>
        }
        <OTPInput
          value={OTP}
          onChange={handleChange}
          autoFocus
          OTPLength={6}
          otpType="number"
          disabled={counter == 0 && !isEmailOtp}
          style={styles.otp}
          secure
          inputStyles={{ ...styles.otpInput, opacity: counter == 0 && !isEmailOtp ? '0.5' : '' }}
        />
        <Typography style={styles.errorMsg} hidden={!isError}>
          {notifMsg}
        </Typography>
        <div hidden={isEmailOtp} align='center'>
          <div hidden={!attemptError}>
            <Typography style={{ ...styles.normalFont, color: '#E24C4C' }}>
              Oops! You have reached OTP Limits, You can request a new OTP on
            </Typography>
            <Typography style={{ ...styles.normalFont, color: '#E24C4C' }}>
              {attemptErrorMsg}.
            </Typography>
          </div>
          <Typography style={{ ...styles.normalFont, padding: '20px 0 20px 0' }} hidden={attemptError}>
            Code will expire in <strong>{calculateDuration(counter)}</strong>
          </Typography>
        </div>
      </div>
      <div style={styles.buttonDiv}>
        <button
          onClick={submitOTP}
          style={{ ...styles.subBtn, opacity: OTP.length < 6 || counter === 0 && !isEmailOtp ? '.5' : '1', margin: isEmailOtp && '20px 0 0 0' }}
          disabled={subBtnLoading || OTP.length < 6 || counter === 0 && !isEmailOtp}
          className={`btn--${segments}`}
        >
          {subBtnLoading ? <Spin size='small' /> : 'Submit'}
        </button>
        <button
          style={{ ...styles.resBtn, opacity: counter !== 0 ? '0.5' : '' }}
          disabled={counter !== 0 || resBtnLoading || attemptError}
          // onMouseOver={handleMouseOver}
          // onMouseLeave={handleMouseLeave}
          onClick={handleResend}
          hidden={attemptError || isEmailOtp}
          className={`outline-btn--${segments}`}
        >
          {resBtnLoading ? <Spin size='small' /> : 'Resend Code'}
        </button>
      </div>
      <div hidden={isEmailOtp}>
        <div style={styles.dividerDiv}>
          <Divider style={styles.divider} />
          <Typography style={styles.or}>OR</Typography>
          <Divider style={styles.divider} />
        </div>
       <div align='center'>
        <a
            style={styles.btnEmail}
            onClick={onEmailOtp}
            disabled={emailBtnLoading || subBtnLoading}
            className={`link-text--${segments}`}
          >Send OTP code via Email</a>
       </div>
      </div>
      <Typography style={{ ...styles.normalFont, fontSize: 16, padding: '24px 0', color: 'rgba(43, 45, 50, 0.64)' }} hidden={isEmailOtp || attemptError}>
        “<strong>Resend Code</strong>” will be enabled after <strong>{calculateDuration(counter)}</strong>.
      </Typography>
    </Modal>
  )
}

const styles = {
  root: {

  },
  title: {
    color: '#000000',
    fontSize: 28,
    fontWeight: 'bold',
    textAlign: 'left',
    padding: '24px 0 20px 0'
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  subtitle: {
    color: '#000000',
    fontSize: 28,
    fontWeight: '300',
    padding: '16px 0'
  },
  normalFont: {
    color: '#2B2D32',
    fontSize: 14,
    textAlign: 'center',
    padding: '0 26px',
  },
  otp: {
    padding: '32px 0 8px 0'
  },
  otpInput: {
    height: 89,
    width: 48,
    fontSize: "28px",
    color: "#2b2d33",
    fontWeight: "600",
    background: "#E6EAF0",
    boxSizing: "border-box",
    border: "0px solid #d1d5dd",
    borderRadius: "4px",
    margin: '0 8px'
  },
  subBtn: {
    margin: '0px 0px 0px 0px',
    height: 40,
    width: 102,
    // backgroundColor: '#F5922F',
    // color: '#FFFFFF',
    borderRadius: 4,
    fontSize: 16,
    fontWeight: '600',
  },
  resBtn: {
    height: 40,
    // width: 102,
    // color: '#F5922F',
    borderRadius: 4,
    fontSize: 16,
    fontWeight: 'bold',
    // borderColor: '#FFF',
    // padding: 0,
    margin: '12px 0 0 0'
  },
  notif: {
    backgroundColor: '#1DD28B',
    fontSize: 14,
    fontWeight: 'bold',
    width: '100%',
    borderRadius: 4,
    padding: '8px 24px',
    textAlign: 'center',
    display: 'flex',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.24)',
    alignItems: 'center',
  },
  affix: {
    position: 'absolute',
    zIndex: '100',
    top: '18%',
    width: '88%'
  },
  divider: {
    margin: '0 20px',
    padding: 1
  },
  dividerDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '22%',
    left: '39%',
    position: 'relative',
    color: '#909196',
    padding: '10px 0'
  },
  btnEmail: {
    fontSize: 16,
    // color: '#F5922F',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '12px 0'
  },
  or: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#909196'
  },
  buttonDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  errorMsg: {
    color: '#C0492C',
    fontSize: 14
  }
}

export default OtpModal;