import React from 'react';
import { Icon, Tooltip, Typography, Card } from 'antd';
import SentroLogo from '../../../static/icons/sentro.svg';
import { SentroShop } from '../../../static/icons/dashboard_icons';
import './sentro_card.css';

const { Text } = Typography;

const SentroCard = props => {

    const {
        segments, noMulti
    } = props;

    return(
        <React.Fragment>
            <Card 
                className='sentro-card' 
                size='small'
            >
                <div
                    style={{ 
                        display: "flex", 
                        gap: "1em", 
                        alignItems: "center" 
                    }}
                    className='sentro-div'
                >
                    <div className='sentro-shop' hidden={!noMulti}>
                        <img src={SentroShop} alt="sentro shop logo" />
                    </div>
                    <div style={{ 
                        flex: "1", 
                        display: "flex", 
                        flexDirection: "column", 
                        rowGap: "0.5rem" 
                    }}>
                        <div 
                            style={{
                                display: 'flex',
                                gap: '0.5rem',
                            }}
                            className='sentro-image'
                        >
                            <img src={SentroLogo} alt="sentro" />
                            <Tooltip placement="top" title={"In Sentro you can sell online, accept payments in various forms, and book deliveries here."}>
                                <Icon type="info-circle" style={{ color: '#4993EE', background: '#E5F2FD', borderRadius: 50, fontSize: 16 }} />
                            </Tooltip>
                        </div>
                        <Text 
                            type='secondary'
                            style={{
                                padding: "0 !important",
                                fontSize: "16px",
                            }}
                            className='sentro-caption'
                        >
                            Launch your online shop now!
                        </Text>
                    </div>
                    <div className='visit-store-link-container' style={{ textAlign: 'right' }}>
                        <a href="https://sentro.ph/login" target='blank' className={`text-${segments} boldTextStyle`} style={{ fontSize: "14px"}}>
                            Visit Store
                        </a>
                    </div>
                </div>
            </Card>
        </React.Fragment>
    )
}

export default SentroCard;
