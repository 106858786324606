import React,{useEffect} from 'react'
import cardsStyles from '../../../static/css/Cards';
import{BusinessToday} from '../../../static/icons/index';
import '../../../styles/screens/dashboard.scss';

const BasicCard = (props) => {

    const cs = cardsStyles();
    const firstName = localStorage.getItem("firstName")
    const { isDellReso } = props;

    return(
        <div className={"accountCard"} align='left' style={{ height: 126 }}>
            <div style={{display: 'flex'}}>
                <div>
                    <img style={{height: 126}} src={BusinessToday} />
                </div>

                <div className={cs.webCardText} style={{padding: '30px 32px 0px 30px'}}>
                    <div className={cs.wcHeader}>
                        Hello, {firstName}
                    </div>
                    <div className={cs.wcText}>
                        Here’s what’s happening in your business today.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BasicCard;