import React from 'react'
import FeeStyles from './style.js'

const QRPHPaymentChannelHeaderCard = props => {
    
    const fee = FeeStyles();

    return(
        <div>

            <div className={fee.spaceBetween}>
                <div className={fee.cardLeftDiv}>
                    <div className={fee.headerText}>
                        QRPH Payment Channel
                    </div>
                    <div className={fee.normalText} style={{marginTop: 8}}>
                        Allows you to customize the QRPH payment channel for this user.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QRPHPaymentChannelHeaderCard