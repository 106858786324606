import React,{useEffect} from 'react';
import { makeStyles } from '../payment_details_design'
import {isMobile, isAndroid, isIOS} from 'react-device-detect'
import {Divider, Button, message, Icon} from 'antd';
import {history} from '../../../store/history'
import wallet from "../../../static/img/wallet.svg";
import Instruction from './instruction'
import Reminder from "../../../static/icons/reminder.svg";
import { channels } from '../payment_const'
import useCustomMediaQuery from '../../../hooks/useCustomMediaQuery'
import DynamicQRPH from '../../dynamic_qrph/dynamic_qrph';

const BankPD = props => {
    const media = useCustomMediaQuery()
    const styles = makeStyles(media)

    const {
        paymentLinkUid, details, channel_name, isInstapayGcash
    } = props 
    
    let channel = details.channel
    let channelInstructions = channels[channel].instruction

    const [initialized, setInitialized] = React.useState(false);

    console.log(details)

    function copySample(text, elementId){
        const textField = document.createElement('textarea');
        textField.innerText = text;
        const parentElement = document.getElementById(elementId);
        parentElement.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        parentElement.removeChild(textField);
        message.destroy()
        message.info(`Copied ${elementId}`)
    }

    const gcashLink = (
        <div>
        {isAndroid ? 
        <div>
            Go to this <a href="gcash://com.globe.gcash.android.sendtobank.refactored.bankcategories.BankCategoryActivity" target="_blank" style={styles.blackLink}>link</a> or Go to your GCash then click Transfer.
        </div>
        : 
        isIOS?
        <div>
            Go to this <a href="gcash://gcash.sendmoney"  target="_blank" style={styles.blackLink}>link</a> or Go to your GCash then click Transfer.
        </div>
        :
        <div>
            Go to your GCash then click Transfer.
        </div>
        }
        </div>
    )

    const UpayAccountDetails = 
        <div>
           <div>
                Copy each required information on your GCash app.
           </div>
           <br />
           <div style={{width: '118%', marginLeft: '-60px', backgroundColor: '#F4F6F9', padding: '20px', borderTop: '1px solid #E6EAF0', borderBottom: '1px solid #E6EAF0'}}>
                <strong>Account Details:</strong>
                <br />
                <div style={styles.accountDetailsRow}>
                    <div style={styles.accountDetailsLabel}>Receiving Bank:</div> 
                    <div style={{...styles.accountDetailsInput}}>UnionBank of the Philippines</div>
                </div>
                <div style={styles.accountDetailsRow}>
                    <div style={styles.accountDetailsLabel}>Amount:</div> 
                    <div style={{display: 'inline-flex', alignItems: 'center', gap: '12px', justifyContent: 'flex-end', maxWidth: '244px'}}>
                        <text style={styles.accountDetailsInput} value={details.amount} id="Amount" readOnly>{details && details.amount}</text>
                        <button
                            style={styles.accountDetailsBtn}
                            onClick={() => copySample(details.amount, 'Amount')}>
                            Copy
                        </button>
                    </div>
                </div>
                <div style={styles.accountDetailsRow}>
                    <div style={styles.accountDetailsLabel}>Account Name:</div> 
                    <div style={{display: 'inline-flex', alignItems: 'center', gap: '12px', justifyContent: 'flex-end', maxWidth: '244px'}}>
                        <text style={styles.accountDetailsInput} value={details.payload.account_name} id="Account Name" readOnly>{details && details.payload && details.payload.account_name}</text>
                        <button
                            style={styles.accountDetailsBtn}
                            onClick={() => copySample(details.payload.account_name, 'Account Name')}>
                            Copy
                        </button>
                    </div>
                </div>
                <div style={styles.accountDetailsRow}>
                    <span style={styles.accountDetailsLabel}>Account Number:</span> 
                    <div style={{display: 'block', alignItems: 'center', gap: '12px', justifyContent: 'flex-end', maxWidth: '244px'}}>
                        <text style={styles.accountDetailsInput} value={details.payload.ref_number} id="Account Number">{details && details.payload && details.payload.ref_number}</text>
                        <button
                            style={styles.accountDetailsBtn}
                            onClick={() => copySample(details.payload.ref_number, 'Account Number')}>
                            Copy
                        </button>
                    </div>
                </div>
                {/* <div style={styles.accountDetailsReminder}>
                    <Icon type="exclamation-circle" theme="filled" style={styles.accountDetailsExc} />
                    <div>
                        <text style={styles.accountDetailsReFont}>put the Account number in the account number field</text>
                    </div>
                </div> */}
           </div>
        </div>

    if (!initialized && isInstapayGcash) {
        channelInstructions.splice(0, 1, UpayAccountDetails);
        setInitialized(true);
    }

    const getParam1Param2 = () => {
        const payload = details.payload;
        
        let param1Name = "Param 1";
        let param1Value = "";
        let param2Name = "Param 2";
        let param2Value = "";

        if (payload && (payload.param1 || payload.param2)) {
            if (typeof payload.param1 === "object" || typeof payload.param2 === "object") {
                param1Name = (payload.param1 && payload.param1.name && payload.param1.name != "") ? payload.param1.name : "Param 1";
                param1Value = (payload.param1 && payload.param1.value) ? payload.param1.value : "";
                param2Name = (payload.param2 && payload.param2.name && payload.param2.name != "") ? payload.param2.name : "Param 2";
                param2Value = (payload.param2 && payload.param2.value) ? payload.param2.value : "";
            } else if (typeof payload.param1 === "string" || typeof payload.param2 === "string") {
                param1Value = payload.param1;
                param2Value = payload.param2;
            }
        }

        return (
            <div hidden={!payload.param1 && !payload.param2}>
                <div style={styles.rowStyle} hidden={!payload.param1}>
                    <div style={styles.detailStyle}>{param1Name}</div> 
                    <div style={styles.labelStyle}>{param1Value}</div>
                </div>

                <div style={styles.rowStyle} hidden={!payload.param2}>
                    <div style={styles.detailStyle}>{param2Name}</div> 
                    <div style={styles.labelStyle}>{param2Value}</div>
                </div>

                <Divider/>
            </div>
        )
    }

    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-between', padding: media.mobile ? '30px 10px 20px 10px' : '30px 50px 20px 50px'}} hidden={details.code == null}>
                <div style={styles.detailStyle}>Reference number</div> 
                <div style={styles.referenceNumberStyle}>{details.code}</div>
            </div>
            {
                details.channel == 'visamc' ?
                <div>
                    <div style={styles.rowStyle}>
                        <div style={styles.detailStyle}>Charge approval code</div> 
                        <div style={styles.labelStyle}>{details.payload['approval_code']}</div>
                    </div>
                    <div style={styles.rowStyle}>
                        <div style={styles.detailStyle}>Merchant name</div> 
                        <div style={styles.labelStyle}>{details.owner}</div>
                    </div>
                    <div style={styles.rowStyle}>
                        <div style={styles.detailStyle}>Date paid</div> 
                        <div style={styles.labelStyle}>{details.date_paid}</div>
                    </div>
                    <div style={styles.rowStyle}>
                        <div style={styles.detailStyle}>Card</div> 
                        <div style={styles.labelStyle}>**** {details.payload['last_four_digits']}</div>
                    </div>
                </div>
                :
                <div style={styles.rowStyle}>
                    <div style={styles.detailStyle}>Account name</div> 
                    <div style={styles.labelStyle}>{details.owner}</div>
                </div>
            }

            <Divider/>

            {getParam1Param2()}

            <div hidden={details.status == 'Cancelled'}>
                <div style={{padding: media.mobile ? '0px 10px 20px 10px' : '0px 50px 10px 50px'}}>
                    <div style={{...styles.detailStyle, fontWeight: 'bold'}}>Description</div><br/> 
                    <div style={styles.textStyle}>{details.description}</div>
                </div>
                <Divider/>
            </div>

            <div style={{display: media.mobile ? 'flex' : '', float: 'right', padding: media.mobile ? '20px 10px 20px 0px' : '0px 50px 20px 0px'}}>
                <span style={{paddingRight: 12, marginTop: media.mobile ? '12px' : ''}}><span style={styles.detailStyle}>{details.status == "Paid" ? "Amount paid:" : "Amount to pay:" }</span> </span>
                <span style={styles.headerStyle}>PHP {parseFloat(details.amount).toLocaleString('en-US', { minimumFractionDigits: 2 })}</span>
            </div>

            <div hidden={details.channel != 'ubpcs'}>
                    <Divider/>

                    <div style={styles.pleaseRememberDiv}>
                        <div style={{paddingRight: 16}}><img src={Reminder} /></div>
                        <div style={styles.textStyle2}>
                            For any refund transactions this can be done in your UnionBank EBC dashboard. 
                        </div>
                    </div>
            </div>

            <div hidden={details.status == "Paid" || details.status == 'Cancelled'}>

            <div hidden={details.status != 'Pending' || details.code == null}>
                    <Divider/>

                    <div style={styles.pleaseRememberDiv}>
                        <div style={{paddingRight: 16}}><img src={Reminder} /></div>
                        <div style={styles.textStyle2}>
                            Please remember that BUx is not responsible for nor does it ensure the delivery, performance or fulfillment of the goods or service that you are paying for.  
                            BUx only ensures that your payment is processed seamlessly and safely. 
                        </div>
                    </div>
            </div>

            <div style={{width: '100%', backgroundColor: '#e6eaf0', margin: '20px 0px 20px 0px', padding: media.mobile ? '8px 0px 4px 10px': '8px 0px 8px 30px'}}>
                <div style={styles.labelStyle}>How To Pay Instructions</div>
            </div>

            <div style={styles.instructionStyle}>
                <div style={{marginRight: '30px'}}>
                    <div style={styles.detailStyle}>1.</div> 
                </div>
                {
                    channel == 'gcash' || channel == 'grabpay' ?
                    <div style={styles.textStyle}>
                            Login to <a style={styles.blackLink} href={details.banking_url}>{channel == 'gcash' ? "GCash" : 'GrabPay'} Online</a>.
                    </div> 
                    :
                    (channel == 'billease' || channel == "billease_sn") ?
                    <div style={styles.textStyle}>
                        Go to <a style={styles.blackLink} href={details.banking_url}>BillEase</a> checkout page.
                    </div>
                    :
                    channel === 'MBTC' ?
                    <div style={styles.textStyle}>      
                        Click on this <a href={details.banking_url} target="_blank" style={styles.blackLink}>link</a> to redirect and login to Metrobank Online Banking.
                    </div>
                    :
                    isInstapayGcash ?
                    <div style={styles.textStyle}>      
                        {isMobile? gcashLink : `Go to your GCash then click Transfer.`}
                    </div>
                    :
                    channel === 'instapay_allbank' ?
                    <div style={styles.textStyle}>
                        <span>Scan the <span style={{ fontWeight: "bold" }}>QRPH</span> below using your local bank or e-wallet app.</span>
                        <div style={{ marginBottom: "1rem" }}></div>
                        <DynamicQRPH
                            staticQRPHImageURL={details.channel_data.qr_code_image}
                            displayedName={details.channel_data.displayed_name}
                            amount={parseFloat(details.amount)}
                            notes={["One-time use only"]}
                            paymentLinkUid={paymentLinkUid}
                        />
                    </div>
                    :
                    <div style={styles.textStyle}>
                        Click on this <a href={details.banking_url} target="_blank" style={styles.blackLink}>link</a> to pay{details.payload.scraped_response ? <span> or scan the <span style={{ fontWeight: "bold" }}>QRPH</span> below using your local bank or e-wallet app.</span> : "."}
                        {details.payload.scraped_response ? <div style={{ marginBottom: "1rem" }}></div> : ""}
                        {details.payload.scraped_response ? <img style={{ width: "150px" }} src={details.payload.scraped_response.qr_image_link} alt="UPAY QRPH" /> : ""}
                    </div>
                }
            </div>

            {
                channelInstructions.map((item, key) => {
                    return(
                        <div key={key} style={styles.instructionStyle}>
                            <div style={{marginRight: '30px'}}>
                                <div style={styles.detailStyle}>{key+2}.</div> 
                            </div>

                            <div style={{...styles.textStyle, width: '100%'}}>        
                                {item}                
                            </div>
                        </div>
                    )
                })
            }

                <div hidden={!isInstapayGcash}>
                    <strong style={{color: '#2B2D32', paddingLeft: '32px'}}>Reminder:</strong>
                    <br /><br />
                    <ul style={{color: '#2B2D32', fontSize: '16px', fontWeight: '400', paddingLeft: '50px'}}>
                        <li>Account number are valid for one-time use only.</li>
                        <li>InstaPay charges from your bank will apply.</li>
                        <li>If payment has been made, kindly wait for your confirmation message.</li>
                    </ul>
                </div>

            <div style={styles.dpDisclaimer}>
                <div style={{color: '#000000', fontWeight: 'bold'}}>Disclaimer:</div> 
                {channels[channel].disclaimer}
            </div>

            </div>
        </div>
    )
}

export default BankPD;
