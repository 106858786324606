import React from 'react';
import { history } from '../../store/history';
import { Button, Dropdown, Divider, Badge, Menu } from 'antd';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import * as Icons from '../../static/images/home/index';
import {me, gig, sme, biz } from '../../static/images/home/index';
import CloseIcon from '../../static/icons/close.svg';
import BG from '../../static/images/union2.svg';
import dropdown_icon from '../../static/icons/dropdown_icon.svg'
import {FAQ, Terms, How, Pricing, Logout, AccountLevels} from '../../static/icons/options/index'
import { NotifBell } from '../../static/icons/notification_icons';
import { hideTooltips } from '../../layouts/AppBar/appBarComponent/appBarFormAction';
import { setInviModal, showFundsTooltip } from '../sideBar/SideBar';
import NotificationDropdown from '../notification/header_dropdown';
import * as Style from '../../layouts/AppBar/appBarComponent/appBarFormStyle'
import tutorialActions from '../tutorials/tutorialActions';
import GenerateDrawer from '../generate_drawer/generate_drawer';
import PayoutDrawer from '../payout_drawer/payout_drawer';
import { Typography } from '@material-ui/core';
import { isMobile } from 'react-device-detect';

const AppBarHeader = props => {
    const isSmallReso = window.screen.width * window.devicePixelRatio === 1331 && window.screen.height * window.devicePixelRatio === 669;
    const isDell = window.screen.width * window.devicePixelRatio === 1360 && window.screen.height * window.devicePixelRatio === 765;
    const isDell1 = window.screen.width * window.devicePixelRatio === 1366 && window.screen.height * window.devicePixelRatio === 768;
    
    const {
        userType,
        level,
        testMode,
        isMultiwallet,
        disablePaymentLink,
        isAlias,
        collapsed,
        setCollapsed,
        canAliasCreatePaymentLink,
        logout,
        segments
    } = props;

    const tradeName = localStorage.getItem("tradeName")
    const firstName = localStorage.getItem("firstName");
    const lastName = localStorage.getItem("lastName");
    const username = localStorage.getItem("username");
    const email = localStorage.getItem("email");
    const subuserRole = localStorage.getItem("subuserRole");
    const isChild = localStorage.getItem("is_child") === 'true';
    const subCheckout = localStorage.getItem('sub_checkout_enabled') === 'true';
    const kybRequired = localStorage.getItem('kybRequired') === 'true';
    const hasCashbuxPrivilege = localStorage.getItem('has_cashbux_privilege');
    
    const HtmlTooltip = withStyles(theme => ({
        tooltip: {
            backgroundColor: '#FFFFFF00',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 400,
            fontSize: theme.typography.pxToRem(12),
            backgroundImage: `url(${BG })`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            marginTop: '-10px'
        },
    }))(Tooltip);

    const generateTooltipVisibility = useSelector(state => state.tooltip.generateTooltip);
    const startDemo = useSelector(state => state.tutorials.startDemo);
    const notifCount = useSelector(state => state.notification.notificationCount)

    const dispatch = useDispatch();
    const [visible, setShowDrawer] = React.useState(false);
    const [dropdownVisible, setDropdownVisible] = React.useState(false)
    const [add_money_enabled, setAddMoneyEnabled] = React.useState(true);

    const defined_wallet_settings = JSON.parse(localStorage.getItem('defined_wallet_settings'));
    const bannerURL = localStorage.getItem("banner_url");

    React.useEffect(()=>{
        try {
            setAddMoneyEnabled(defined_wallet_settings.add_money_enabled);
        } catch (err){
            setAddMoneyEnabled(true);
        }
    });

    const menu = (
        <Menu  style={{width: '180px', margin:'20px 20px 0px 0px', padding: '5px'}}>
          <Menu.Item style={Style.menuStyle} onClick={ () => window.location.href = 'https://faq.bux.ph/hc/en-us'}>
            <img alt="FAQ" src={FAQ} style={{marginRight: '4px'}}/> FAQ
          </Menu.Item>
          <Menu.Item style={Style.menuStyle} onClick={ () => history.push('/terms_and_privacy')}>
            <img alt="Terms Icon" src={Terms} style={{marginRight: '4px'}}/> Terms and Policy
          </Menu.Item>
          <Menu.Item hidden={userType === 'SW'} style={Style.menuStyle} onClick={ () => history.push('/account_levels')}>
            <img alt="Account Levels Icon" src={AccountLevels} style={{marginRight: '4px'}}/> Account Levels
          </Menu.Item>
          <Menu.Item style={Style.menuStyle} hidden={userType === "SW" || testMode || isMobile || isChild} 
            onClick={()=>{
                dispatch(tutorialActions.setStartDemo(true));
                dispatch(tutorialActions.setTutorialStartModal(true));}
                }
            >
            <img alt="How Icon" src={How} style={{marginRight: '4px'}}/> How it works?
          </Menu.Item>
          <Menu.Item style={Style.menuStyle} onClick={ () => history.push('/pricing')}>
            <img alt="Prcing Icon" src={Pricing} style={{marginRight: '4px'}}/> Pricing Table
          </Menu.Item>
          <Menu.Divider style={{margin: '10px 0px 5px 0px'}}/>
          <Menu.Item style={Style.menuStyle} onClick={ () => logout()}>
            <img alt="Logout Icon" src={Logout} style={{marginRight: '8px'}}/>Logout
          </Menu.Item>
        </Menu>
    );


    let logo = segments === 'me_' ? me : segments === 'gig' ? gig :
                segments === 'sme' ? sme : segments === 'biz' ? biz : Icons.WebBux;

    return (
        <div>
            <div className="headerContent">
                <div style={{display: 'flex', marginLeft: 4}}>
                    <div hidden={isSmallReso || isDell || isDell1}>
                        <button className="menuBtn" onClick={() => setCollapsed(!collapsed)} id="collapse-btn">
                            <img src={Icons.Menu} alt="menu" className={!collapsed ? "menuState" : ""} />
                        </button>
                    </div>
                    
                    <div>
                        { bannerURL ?
                        <img
                        src={bannerURL}
                        // If MW is CashBux, double the size of header logo (ckb-322)
                        style={{height: hasCashbuxPrivilege === "true" ? 40 : 20, marginLeft: 24}}
                        alt="Logo" 
                        onClick={ 
                            () => 
                            userType === 'AD' || 
                            userType === "OP" ? history.push('/orders') : 
                            history.push(testMode ? '/test/dashboard' : '/dashboard') } />
                            :

                        <img
                        src={logo} 
                        style={{width: 84, marginLeft: 24}}
                        alt="BUx Logo" 
                        onClick={ 
                            () => 
                            userType === 'AD' || 
                            userType === "OP" ? history.push('/orders') : 
                            history.push(testMode ? '/test/dashboard' : '/dashboard') } />
                        }
                    </div>
                </div>
                
                <div style={{display: 'flex'}}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Tooltip title={
                                ((level <= 0 && userType == 'SW' && subuserRole == 'ME') || 
                                (level != 5 && userType == 'SW' && subuserRole == 'CO' && kybRequired)) && !testMode
                                ? "Account has not yet been approved." : ''
                            }>
                            <button disabled={
                                        ((level <= 0 && userType == 'SW' && subuserRole == 'ME') || 
                                        (level != 5 && userType == 'SW' && subuserRole == 'CO' && kybRequired)) && !testMode
                                    }
                                    hidden={disablePaymentLink || ((!isMultiwallet || isChild) && userType !== 'SW') || (segments === 'sme' && userType !== 'SW')  || !add_money_enabled} 
                                    className={`outline-btn--${segments} right-8`}
                                    style={{
                                        cursor: disablePaymentLink || ((!isMultiwallet || isChild) && userType !== 'SW') ? 'not-allowed' : 'pointer',
                                        opacity: (parseInt(level) < 1 && userType == 'SW' && subuserRole == 'ME') ||
                                                 (parseInt(level) < 1 && userType == 'SW' && subuserRole == 'CO' && kybRequired) ? 0.5 : 1 
                                    }} 
                                    onClick={() => history.push(testMode ? '/test/wallet/add_money' : '/wallet/add_money')}>
                            Add money
                            </button>
                        </Tooltip>
                        
                        <HtmlTooltip 
                            open={generateTooltipVisibility} interactive={true}
                            title={
                                <div className="titleDiv">
                                    <div className="closeIconDiv">
                                    <img alt="Close Icon" src={CloseIcon} onClick={()=>{setInviModal(); dispatch(hideTooltips())}}/>
                                    </div>

                                    <div className="titleText">
                                    This tab allows you to share Payment Link to your customers.
                                    </div>
                                    <div className="btnPosition">
                                    <Button key="back" style={{width: '44%', marginRight: '2%'}} onClick={()=> {showFundsTooltip(); dispatch(hideTooltips())}}>
                                        Back
                                    </Button>
                                    <Button key="submit" type="primary" style={{marginRight: '10px', width: '44%'}} onClick={()=>{setInviModal(); dispatch(hideTooltips())}}>
                                        End
                                    </Button>
                                    </div>
                                </div>
                            }
                        >
                            <Tooltip title={
                                (userType === "CO" && level != 5 && !testMode) || 
                                (userType === "SW" && subuserRole == 'ME' && level <= 0) ||
                                (userType === "SW" && subuserRole == 'CO' && kybRequired && level != 5)
                                ? "Account has not yet been approved." : ''}>

                                <button onClick={() => setShowDrawer(true)}
                                    className={`btn--${segments} right-20`}
                                    disabled={disablePaymentLink}
                                    style={{
                                        display: 
                                        (isAlias && canAliasCreatePaymentLink && localStorage.getItem("merchant_bias") !== "true") || 
                                        (userType === "ME" || (userType === "CO" && !isAlias)) || 
                                        (userType === 'SW' && subCheckout) ||
                                        (userType === 'SW' && subuserRole == 'CO' && !kybRequired) ||
                                        (userType === 'SW' && subuserRole == 'CO' && kybRequired && level == 5)
                                        ? "inline" : "none"
                                    }}
                                    >Generate link
                                </button>
                            </Tooltip>
                        </HtmlTooltip>

                        <Typography style={{marginRight: '20px', fontWeight: '600', fontSize: '14px', maxWidth: '154px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap' }}>
                            { 
                            startDemo ? 'Your Name' 
                                : 
                            firstName === "" && lastName === "" && userType === 'SW' ? email 
                                : 
                            firstName !== "" && lastName !== ""? firstName+ " "+ lastName 
                                : 
                            username 
                            }
                        </Typography>
                        <Divider type="vertical"/>
                        <Dropdown 
                            visible={dropdownVisible}
                            getPopupContainer={
                                    triggerNode => triggerNode.parentNode 
                                    } 
                                    overlay={
                                    <NotificationDropdown hideDropdown={()=>setDropdownVisible(false)} />
                                    } 
                                    placement="bottomLeft"
                                    style={{padding: '0px 20px'}} 
                                    disabled={testMode}>

                            <Badge count={notifCount} className="badgeIcon">
                            {/* <Icon type="bell" style={{fontSize: '18px'}}/> */}
                            <a onClick={()=>setDropdownVisible(!dropdownVisible)} href>
                                <img src={NotifBell} alt="Bell"  />
                            </a>
                            </Badge>
                        </Dropdown>

                        <Divider type="vertical"/>
                        <Dropdown getPopupContainer={
                                    triggerNode => triggerNode.parentNode 
                                    } 
                                    overlay={menu} p
                                    lacement="bottomCenter" 
                                    trigger={['click']}>

                            <img src={dropdown_icon} alt="#" className="caret-img" style={{padding: '10px'}} />
                        </Dropdown>
                    </div>

                </div>
        
                <GenerateDrawer visible={visible} tradeName={tradeName} setShowDrawer={setShowDrawer} />
                <PayoutDrawer />  
            </div>
        
        </div>
    );
};

export default AppBarHeader;
