import axios from 'axios';

export const DynamicQRPHServices = {
  saveDynamicQRPHBuxImageApi: async (
    paymentLinkUid,
  ) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/dynamic_qrph/${paymentLinkUid}/save/`,
    );

    return response.data.image_url;
  },
}