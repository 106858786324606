import React from 'react';
import { Card, Spin, Button, Modal, Select, Typography} from 'antd';
import { useMediaQuery } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts';
import { history } from '../../store/history';
import dashboardStyles from '../../static/css/Dashboard';
import expiryStyle from '../../static/css/Expiry';
import { useDispatch, useSelector } from '../../components/dashboard/react-redux-hooks';
import { isMobile, isTablet } from 'react-device-detect';
import axios from 'axios'
import { Handle401 } from '../../components/handle401/handle401'
import MetaTag from '../../components/meta_tag/meta_tag'
import moment from 'moment';
import BasicCard from '../../components/cards/web/basic_card'
import AccountCard from '../../components/cards/web/account_card'
import BankAdvisoryCard from '../../components/cards/web/bank_advisory_card';
import { getDashboardGraphData } from '../../components/dashboard/dashboardServices';
import { dashboardActions } from '../../components/dashboard/dashboardAction';
import AccountLimitsTracker from '../../components/dashboard/account_limits_tracker/account_limits_tracker.js';
import OrderSalesCard from "../../components/dashboard/order_sales_card/order_sales_card";
import MyCheckoutCard from "../../components/dashboard/my_checkout/my_checkout_card";
import DashboardSidebar from '../../components/dashboard/dashboard_sidebar';
import MarketPromotion from '../../components/dashboard/marketing_promotion/market_promotions'
import TestModeCard from '../../components/cards/web/test_mode_card';
import Lock from '../../static/icons/lock.svg'
import { ConvertWelcomeBanner } from '../../static/icons/index'
import { segmentStroke } from '../../components/constants/constants';
import WhatsNewQr from '../../components/account_settings/bux_qr/whats_new_modal';
import { DBMultiWalletCard } from '../../components/multiwallet';
import { useTheme } from '@material-ui/core/styles';
import * as Icons from '../../static/icons/dashboard_icons/index';
import SentroCard from '../../components/dashboard/sentro/sentro_card';
import '../../App.scss';
import GCashSubMidCard from '../../components/cards/web/gcash_submid_card';
import DashboardStyles from './styles.module.css'
import StaticQRPH from "../../components/static_qrph/static_qrph";

const { Option } = Select;

const Dashboard = props => {

    const isSubDomain = process.env.REACT_APP_ROOT_URL !== window.location.hostname;
    const noTradeName = localStorage.getItem("tradeName") === ""
    const subwalletType = localStorage.getItem("subwallet_type")

    const theme = useTheme();
    const smallReso = useMediaQuery(theme.breakpoints.down('sm'))
    const isMac = window.screen.width * window.devicePixelRatio === 1440 && window.screen.height * window.devicePixelRatio === 900;
    const isSmallReso = window.screen.width * window.devicePixelRatio === 1331 && window.screen.height * window.devicePixelRatio === 669;
    const isDell = window.screen.width * window.devicePixelRatio === 1360 && window.screen.height * window.devicePixelRatio === 765;
    const isDell1 = window.screen.width * window.devicePixelRatio === 1366 && window.screen.height * window.devicePixelRatio === 768;

    const isMultiwallet = localStorage.getItem('is_multiwallet') === 'true';
    const isDellReso = (isSmallReso || isDell || isDell1)

    const db = dashboardStyles();
    const es = expiryStyle();
    const testAccess = ['SW', 'CO']
    const userType = localStorage.getItem("userType");

    const [dashboard, setDashboard] = React.useState("")
    const [datas, setDatas] = React.useState({})
    const dispatch = useDispatch();
    const [graphData, setGraphData] = React.useState(null)
    const [buttonClicked, setButtonClicked] = React.useState('1w');
    const testMode = (history.location.pathname.indexOf('/test') > -1 && testAccess.includes(userType));
    const [showConvertWelcomeModal, setShowConvertWelcomeModal] = React.useState(false)
    const [showWhatsNew, setShowWhatsNew] = React.useState(false);

    const endpoints = useSelector(state => state.child_endpoints.endpoints);
    const isChild = localStorage.getItem("is_child") === "true";
    const kybStep = localStorage.getItem('KYBStep');
    const level = localStorage.getItem("Level")
    const step = localStorage.getItem("Step")
    const stepIncluded = [12, 16]
    const segments = localStorage.getItem("segments")

    const approvedStep = [11, 12]
    const disablePaymentLink = (userType === "CO" && kybStep < 11) &&
        ((!testMode || localStorage.getItem("userType") === "CO") &&
            (!approvedStep.includes(parseInt(kybStep)) && !testMode)) || level <= 0;
    const isCardRejected = (userType == "CO" && kybStep == 10) || 
                            (level == "0" && step == 9) || 
                            (level == "1" && step == 15) || 
                            (level == "2" && step == 19);

    const defined_wallet_settings = JSON.parse(localStorage.getItem('defined_wallet_settings'));
    let myQRCodeEnabled = false;
    if (defined_wallet_settings != null){
        myQRCodeEnabled = defined_wallet_settings.my_qr_code_enabled;
    }
    
    const noMulti = !isMultiwallet || isChild || testMode

    const subCheckout = localStorage.getItem('sub_checkout_enabled') === 'true';
    const [showCompleteProf, setShowCompleteProf] = React.useState(false);
    
    let isEnterprise = false;
    let hideAddMoneyTab = true;
    if(segments != null){

        isEnterprise = segments.includes('sme', 'biz');
        hideAddMoneyTab = (userType === 'ME' || disablePaymentLink || ((!isMultiwallet || isChild) && userType !== 'SW') ||
            (['me_', 'sme'].includes(segments) && userType !== 'SW'));
    }

    const convertWelcomeMessage =
        `
        Welcome to your ${segments === 'me_' ? 'BUxME' : segments === 'gig' ? 'BUxGIG' :
            segments === 'sme' ? 'BUxSME' : segments === 'biz' ? 'BUxBIZ' : ''} account. 
        Getting started in your 
        ${(segments === 'me_' || segments === 'gig') ? 'freelance journey' : 'business'}? 
        We're here to support your business needs.
        `
    let week = (dashboard ? dashboard.data : [])

    let data = [];

    week.map((item) => {
        return (
            data.push({
                name: item[0],
                amount: item[1],
            })
        )
    })

    let lastEntry = (data ? data[data.length - 1] : '')

    const graphButtons = ['1w', 'mtd', 'ytd', 'all'];

    const graph_options = graphButtons.map((item, i) => {
        return (
            <Option key={i}
                style={{ width: 115 }}
                value={item}>
                {item === '1w' ? '1 week' :
                    item === 'mtd' ? 'Monthly' :
                        item === 'ytd' ? 'Yearly' :
                            'All'}
            </Option>
        )
    })

    const handleClick = (event, type) => {
        console.log(event)
        dispatch(dashboardActions.setData(null));

        let value = type === 'btn' ? event.target.value : event

        setButtonClicked(value);
        getGraphData(value);
    }

    const getGraphData = async (event) => {
        try {
            const response = await getDashboardGraphData(event);

            let graphDataConverted = [];
            response && response.map((item) => {
                return (
                    graphDataConverted.push({
                        name: item.name,
                        amount: parseFloat(item.amount),
                    })
                )
            })

            setGraphData(graphDataConverted)

        } catch (error) {
            if ((error.response) && (error.response.status === 401)) {
                Handle401()
            }
        }
    };

    

    const ConversionWelcomeModal = () => {
        return (
            <Modal
                centered
                width={450}
                visible={showConvertWelcomeModal}
                onCancel={() => done()}
                footer={null}>
                <div>
                    <div style={{ padding: '30px 0px' }}>
                        <img src={ConvertWelcomeBanner} style={{ width: '100%' }} alt="logo" />
                    </div>
                    <div className={`boldTextStyle`} style={{ color: '#2B2D32', fontSize: '18px' }}>
                        Congratulations
                    </div>

                    <div className={es.message1} style={{ color: '#000000', textAlign: 'justify' }}>
                        <p>
                            {convertWelcomeMessage}
                        </p>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-end', align: 'right' }}>
                        <button
                            className={`big-btn btn-width btn--${segments}`}
                            onClick={() => done()}
                        >
                            Got It
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }

    const ConvertConfirmationModal = () => {
        return (
            <Modal
                width={360}
                visible={false}
                onCancel={() => done(false)}
                footer={null}
            >
                <div className={`boldTextStyle`} style={{ color: '#2B2D32', fontSize: '18px', padding:'20px 0px' }}>
                    Are you sure you want to convert account level?
                </div>

                <div style={{display:'flex',justifyContent: 'flex-end', paddingBottom: '15px'}}>
                    <button
                        onClick={() => done(false)}
                        className={`big-btn btn-width outline-btn--${segments} text-${segments}`}
                        style={{
                            width: isMobile ? '120px' : '150px', marginLeft: '15px'
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className={`big-btn btn-width btn--${segments}`}
                        onClick={() => done(false)}
                        style={{
                            width: isMobile ? '120px' : '150px', marginLeft: '15px'
                        }}
                    >
                        Confirm
                    </button>
                </div>
            </Modal>
        )
    }

    function done() {
        setShowConvertWelcomeModal(false)
    }


    React.useEffect(() => {
        let current_path = window.location.pathname
        if (localStorage.getItem("jwtToken") === "" || localStorage.getItem("jwtToken") == null) {
            history.push('/login?next=' + current_path)
        }
        else {

            if (["AD", "OP", "KB", "FI", "BS", "AP", "EN"].includes(userType)
                || (userType === "CO" && localStorage.getItem("merchant_bias") === "true")
            ) {
                history.push('/orders')
            }
            else if (userType === "PS" || userType === "PF") {
                history.push('/submissions_kyb')
            }
            else if (userType === "MA") {
                history.push('/overview')
            }
            else if (userType === "KC") {
                history.push('/submissions')
            }
            else if (userType === "SD") {
                history.push('/submissions_kyb')
            }
            else if (userType === "SW" && isSubDomain && testMode) {
                    history.push('/test/bux_wallet');
            }

            else if (history.location.pathname.indexOf('/test') > -1 && !testAccess.includes(userType)) {
                history.push('/dashboard')
            }
            else {
                getDashboardNow()
                getGraphData(buttonClicked)

            }
        }
    }, []);

    React.useEffect(() => {
        if (!isChild || !endpoints.length) {
            return;
        }

        // Redirect if URL was manually typed
        if (!endpoints.includes('dashboard')) {
            history.push(endpoints[0]);
        }
        setShowWhatsNew((localStorage.getItem('tradeName') || level == 0) ? false : true);

    }, [endpoints])

    const myCheckoutCardHidden = React.useMemo(() => {
        return noTradeName || userType === 'SW' && !subCheckout || !datas.checkout_enabled
    }, [noTradeName, userType, subCheckout, datas.checkout_enabled]);
    const dbMultiWalletCardHidden = !isMultiwallet || isChild || testMode;

    const getDashboardNow = async () => {
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        try {
            const url = testMode ? '/api/sandbox/dashboard/' : '/api/dashboard/';
            let response = await axios.get(
                process.env.REACT_APP_API_URL + url, yourConfig
            );
            console.log(response.data)

            setDatas(response.data)
            setDashboard(response.data.week)
            setShowConvertWelcomeModal(response.data.show_conversion_welcome)
        }

        catch (error) {
            if ((error.response) && (error.response.status === 401)) {
                Handle401()
            }

        }
    }

    // If endpoints have not been loaded yet. Hook on endpoints will handle redirect
    if (isChild && isMobile && !endpoints.includes('dashboard')) {
        return null;
    }

    const transactionBtns = [
        {
            icon: Icons.GenerateLink,
            title: 'Generate Link',
            link: '/mobile/generate_code/',
            key: "generate_link",
            hidden: level == 0 || !datas.generate_payment_link_enabled,
        },
        {
            icon: Icons.AddMoney,
            title: 'Add Money',
            link: '/mobile/multiwallet/',
            key: "add_money",
            hidden: hideAddMoneyTab || level == 0 || !datas.add_money_enabled,
        },
        {
            icon: Icons.Payout,
            title: 'Payout',
            link: '/mobile/payout/',
            key: "payout",
            hidden: userType === 'CO' || !datas.payout_enabled,
        },
    ]

    return (
        <div align="left">
            <MetaTag title="Dashboard" />

            {ConversionWelcomeModal()}
            {ConvertConfirmationModal()}


            <WhatsNewQr showModal={showWhatsNew} closeModal={setShowWhatsNew} />

            {
                isMobile || isTablet || smallReso ?

                    <div style={{ 
                        padding: "20px",
                        paddingBottom: "95px",
                    }}>
                        { 
                            datas.show_gcash_submid_card ?
                                <div>
                                    <GCashSubMidCard id='gcashSubMidCard' isMobile={isMobile} isTablet={isTablet} smallReso={smallReso}/>
                                </div>
                            : <></>
                    
                        }
                        <div className="mobile-div">

                            <DashboardSidebar isMobile={isMobile} isTablet={isTablet} smallReso={smallReso} isTestMode={testMode} />

                        </div>

                        <div className="mobile-div">
                            <div className={`transaction-div space-around border-top-${segments}`} align="center">
                                {
                                    transactionBtns.map((item, i) => {
                                        return (
                                            <div 
                                                key={item.key}
                                                hidden={item.hidden}
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    width: "72px",
                                                }}
                                            >
                                                <button 
                                                    onClick={() => {
                                                        if (item.key === 'payout' && level == 0) {
                                                            setShowCompleteProf(true)
                                                        } else {
                                                            history.push(item.link)
                                                        }
                                                    }}
                                                    style={{
                                                        background: "none",
                                                        border: "none",
                                                        padding: "0px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <img 
                                                        src={item.icon} 
                                                        alt={item.title}
                                                        style={{
                                                            width: "48px",
                                                        }}
                                                    />
                                                </button>
                                                <div 
                                                    className="transaction-text"
                                                    style={{
                                                        paddingTop: "8px",
                                                        lineHeight: "normal",
                                                    }}
                                                >
                                                    {item.title}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <div 
                            className="mobile-div"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                rowGap: "1rem",
                            }}
                        >
                            {
                                !testMode &&
                                myQRCodeEnabled &&
                                <StaticQRPH/>
                            }
                            <button className="payout-btn" onClick={() => window.location.href = '/orders'}>
                                <div className="twoCol">
                                    <div style={{ marginTop: 8 }} className="boldTextStyle">View transaction history</div>
                                    <ExpandMoreIcon className={`text-${segments}`} style={{ fontSize: 32, marginTop: 4, transform: 'rotate(270deg)' }} />
                                </div>
                            </button>
                        </div>

                        <div className="mobile-div">
                            <OrderSalesCard id="order-card" data={{ datas, lastEntry }} />
                        </div>

                        <div 
                            className="mobile-div"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                rowGap: "1rem",
                            }}
                        >
                            <AccountLimitsTracker id='tracker-indi' data={datas} />
                            <Card className="chart" bodyStyle={{ padding: 12 }}>
                                <div className="twoCol" style={{ marginBottom: 32 }}>
                                    <div className="sales" style={{ marginTop: 8 }}>
                                        Sales in {moment().format('YYYY')}
                                    </div>
                                    <div id='graph-button'>
                                        <Select
                                            showSearch
                                            onChange={(e) => handleClick(e, 'select')}
                                            placeholder="Select"
                                            style={{ width: '115px', height: '32px' }}
                                            size="large"
                                        >
                                            {graph_options}

                                        </Select>
                                    </div>
                                </div>

                                {graphData ?
                                    <ResponsiveContainer height={250} style={{ width: '100%' }}>
                                        <AreaChart
                                            style={{ marginLeft: '-20px' }}
                                            data={graphData}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip />
                                            <Area type="monotone" dataKey="amount" stroke={segmentStroke[0]} fill={segmentStroke[1]} />
                                        </AreaChart>
                                    </ResponsiveContainer>
                                    :

                                    <div style={{ width: '100%', textAlign: 'center', height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Spin size="large" /></div>
                                }
                            </Card>
                        </div>

                        <div className="mobile-div">
                            <SentroCard hidden={false} segments={segments} />
                        </div>

                        <div className="mobile-div" hidden={userType === 'SW'}>
                            <MarketPromotion smallReso={smallReso} />
                        </div>
                    </div>

                    :
                    <div 
                        className={`${DashboardStyles.dashboardGrid} grid-container`}
                        style={{ 
                            gap: '2rem',
                        }}
                    >
                        <div 
                            className={`col-8 grid-container ${DashboardStyles.dashboardLeft}`} 
                            style={{ 
                                padding: "3rem 0rem",
                                gap: '1rem',
                            }}
                        >
                            <div
                                className='col-12 grid-container'
                                style={{ 
                                    gap: '1rem',
                                    gridAutoRows: 'min-content',
                                    display: `${subwalletType === 'CMP' ? "grid" : "none"}`,
                                }}
                            >
                                <div
                                    className='col-12'
                                >
                                    <BankAdvisoryCard />
                                </div>
                                <div
                                    className='col-12'
                                >
                                    {
                                        testMode ?
                                            <TestModeCard isDellReso={isDellReso} /> 
                                        :
                                            stepIncluded.includes(parseInt(step)) || 
                                            kybStep >= 11 || 
                                            datas && 
                                            moment().diff(
                                                moment(datas.application_submission_date),
                                                'days'
                                            ) > 1 && 
                                            !isCardRejected ?
                                                <BasicCard id='basicCard' isDellReso={isDellReso} />
                                            :
                                                <AccountCard id='accountCard' isDellReso={isDellReso} /> 
                                    }
                                </div>
                            </div>

                            <div
                                className='col-12 grid-container'
                                style={{ 
                                    gap: '1rem',
                                    gridAutoRows: 'min-content',
                                }}
                            >                            
                                
                                <div
                                    className='col-4 col-md-6'
                                >
                                    <OrderSalesCard id="order-card" data={{ datas, lastEntry }} />
                                </div>

                                <div
                                    className='col-4 col-md-6'
                                    style={{
                                        display: `${myCheckoutCardHidden ? "none" : "block"}`,
                                    }}
                                >
                                    <MyCheckoutCard/>
                                </div>

                                <div
                                    className={`${myCheckoutCardHidden ? "col-8" : "col-4"}  col-md-6`}
                                >
                                    <AccountLimitsTracker id='tracker-indi' data={datas} />
                                </div>

                                <div
                                    className={`${DashboardStyles.multiwalletCard} col-5 col-md-6`}
                                    style={{
                                        display: `${dbMultiWalletCardHidden ? "none": "block"}`,
                                    }}
                                >
                                    <DBMultiWalletCard isMac={isMac} />
                                </div>

                                <div
                                    className={`${dbMultiWalletCardHidden ? "col-12": "col-7"} ${DashboardStyles.chartCard} col-md-12`}
                                >
                                    <div style={{ width: '100%', height: '100%' }}>
                                        <Card className="chart" id='graph-bar'>
                                            <div className={db.chartTestMode} hidden={!testMode}>
                                                <img src={Lock} alt="logo" />
                                                <label style={{ paddingTop: 14 }}>This card is only for <strong>Live Mode</strong>.</label>
                                            </div>

                                            <div className="twoCol">
                                                <div className="boldTextStyle">
                                                    Sales in {moment().format('YYYY')}
                                                </div>
                                                <div id='graph-button'>
                                                    <Button.Group>
                                                        {graphButtons.map((item, index) => {
                                                            return (
                                                                <Button key={item}
                                                                    className={`graphBtn ${buttonClicked === item ? `bg-${segments}` : ""}`}
                                                                    style={{
                                                                        backgroundColor: `${buttonClicked === item ? '#273C75' : '#FFFFFF'}`,
                                                                        color: `${buttonClicked === item ? '#FFFFFF' : '#54575F'}`,
                                                                        fontStyle: 'Inter',
                                                                        borderRadius: `${index === 0 ? '8px 0px 0px 8px' : index === 5 ? '0px 8px 8px 0px' : null}`
                                                                    }}
                                                                    value={item}
                                                                    onClick={(e) => handleClick(e, 'btn')}
                                                                    id={`graphBtnSelected`}
                                                                >{item === 'mtd' ? item.charAt(0).toUpperCase() + item.substring(1) :
                                                                    item === 'ytd' ? item.charAt(0).toUpperCase() + item.substring(1) :
                                                                        item === 'all' ? item.charAt(0).toUpperCase() + item.substring(1) :
                                                                            item}</Button>
                                                            )
                                                        })}
                                                    </Button.Group>
                                                </div>
                                            </div>

                                            <div className="top-32">
                                                {graphData ?
                                                    <ResponsiveContainer
                                                        height={isMultiwallet && isMac ? 420 : isMultiwallet ? 330 : 250}
                                                    >
                                                        <AreaChart
                                                            data={graphData}>
                                                            <CartesianGrid strokeDasharray="3 3" />
                                                            <XAxis dataKey="name" />
                                                            <YAxis />
                                                            <Tooltip />
                                                            <Area type="monotone" dataKey="amount" stroke={segmentStroke[0]} fill={segmentStroke[1]} />
                                                        </AreaChart>
                                                    </ResponsiveContainer>
                                                    :
                                                    <div style={{ width: '100%', textAlign: 'center', height: 245, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Spin size="large" />
                                                    </div>

                                                }
                                            </div>
                                        </Card>
                                    </div>

                                </div>

                                <div
                                    className={`${DashboardStyles.sentroCard} col-5 col-md-6`}
                                    style={{
                                        display: `${dbMultiWalletCardHidden ? "none" : "block"}`,
                                    }}
                                    >
                                    <SentroCard segments={segments} noMulti={noMulti}/>
                                </div>
                                
                                <div
                                    className='col-12'
                                >
                                    <MarketPromotion isDellReso={isDellReso} />
                                </div>
                            </div>
                        </div>
                        <div 
                            className={`col-4 ${DashboardStyles.dashboardRight}`}
                            style={{ background: '#E9EEF4', padding: "3rem 2rem" }}
                        >
                            <DashboardSidebar isMobile={isMobile} isDellReso={isDellReso} isTestMode={testMode} />
                        </div>
                    </div>
            }
            
            <Modal
                visible={showCompleteProf} 
                footer={null} 
                onCancel={() => setShowCompleteProf(false)}  
                width={296}
                centered
                bodyStyle={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                style={{borderRadius: 8}}
                closable={false}
                >
                <img src={Icons.CompleteProfAlert} alt='Complete profile first' />
                <Typography style={{
                    fontSize: 16,
                    color: '#2B2D33',
                    padding: '16px 0',
                    textAlign: 'center',
                    }}>
                    Complete your profile now to access Payout feature.
                </Typography>
                <button className={`btn--${segments} btn-height btn-mobile-width`} style={{marginTop: 12}} onClick={() => history.push(isEnterprise ? '/upgrade_enterprise' : '/complete_profile')}>
                    Complete profile now
                </button>
                <button className={`outline-btn--${segments}`} style={{height: 40, width: '100%', marginTop: 16 }} onClick={() => setShowCompleteProf(false)}>
                    Cancel
                </button>
            </Modal>
        </div>
    )


}

export default Dashboard
