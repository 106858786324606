import React from 'react'
import { Spin, message } from 'antd'
import StaticQRPHStyles from './styles.module.css'
import { StaticQRPHServices } from './services';
import axios from 'axios'
import QRPHImage from '../qrph_image/qrph_image';

const StaticQRPH = props => {
    const segments = localStorage.getItem("segments")

    const [staticQRPHImageURL, setStaticQRPHImageURL] = React.useState("");
    const [qrphImageIsRendering, setQRPHImageIsRendering] = React.useState(true);
    const [qrphImageOpacity, setQRPHImageOpacity] = React.useState(0);
    const [staticQRPHGetSuccess, setStaticQRPHGetSuccess] = React.useState(false);
    const [isDownloading, setIsDownloading] = React.useState(false);
    const [displayedName, setDisplayedName] = React.useState("");

    const getStaticQRPH = async () => {
        setQRPHImageOpacity(0);
        try {
            const { imageUrl, displayedName } = await StaticQRPHServices.getStaticQRPHApi();
            setStaticQRPHImageURL(imageUrl);
            setDisplayedName(displayedName);
            setStaticQRPHGetSuccess(true);
        }
        catch (error) {
            setQRPHImageIsRendering(false);
            setStaticQRPHGetSuccess(false);
        }
    }

    const saveStaticQRPHBuxImage = async () => {
        setIsDownloading(true);
        try {
            const buxImageUrl = await StaticQRPHServices.saveStaticQRPHBuxImageApi();
            const buxImageUrlResponse = await axios.get(
                buxImageUrl, 
                { 
                    responseType: 'blob',
                },
            );
            const buxImageFile = new Blob(
                [
                    buxImageUrlResponse.data,
                ],
                { type: "image/*" }
            );
            
            const link = document.createElement('a');
            link.href = URL.createObjectURL(buxImageFile);
            link.download = `qr-code-${Date.now()}.png`;
            document.body.appendChild(link); 
            link.click(); 
            document.body.removeChild(link); 
        }
        catch (error) {
            message.error("Failed to download image.");
        }
        setIsDownloading(false);
    }

    React.useEffect(() => {
        getStaticQRPH();
    }, []);

    const qrphRenderingFailedHidden = React.useMemo(() => {
        return qrphImageIsRendering || staticQRPHGetSuccess;
    }, [qrphImageIsRendering, staticQRPHGetSuccess]);

    const qrphContentHidden = React.useMemo(() => {
        return qrphImageIsRendering || !staticQRPHGetSuccess;
    }, [qrphImageIsRendering, staticQRPHGetSuccess]);

    return(
        <div
            style={{ 
                display: "flex",
            }}
        >
            <div 
                className={StaticQRPHStyles.drawerBodyContent}
                style={{
                    backgroundColor: "#FFFFFF",
                }}
            >
                <div className={StaticQRPHStyles.headers}>
                    <div 
                        id="top" 
                        className="boldTextStyle"
                    >
                        My QR
                    </div>
                </div>

                <div className={StaticQRPHStyles.headerDivider}>
                </div> 
                
                <div 
                    className={StaticQRPHStyles.bodyLoading}
                    hidden={!qrphImageIsRendering}
                >
                    <Spin 
                        size='large'
                        className={StaticQRPHStyles.spinner} 
                    />
                    <span
                        style={{
                            fontWeight: "600",
                            fontSize: "16px",
                            color: "#2B2D33",
                        }}
                    >
                        Loading QR image...
                    </span>
                </div>

                <div 
                    className={StaticQRPHStyles.bodyContentFailed}
                    hidden={qrphRenderingFailedHidden}
                >
                    <span
                        style={{
                            fontWeight: "600",
                            fontSize: "16px",
                            color: "#2B2D33",
                        }}
                    >
                        Something went wrong.
                    </span>
                    <span>
                        Reload the page and try again.
                    </span>
                </div>

                <div 
                    className={StaticQRPHStyles.bodyContent}
                    style={{
                        opacity: qrphImageOpacity,
                        display: `${qrphContentHidden ? "none" : "flex"}`,
                    }}
                >
                    <QRPHImage
                        staticQRPHImageURL={staticQRPHImageURL}
                        displayedName={displayedName}
                        setQRPHImageIsRendering={setQRPHImageIsRendering}
                        setQRPHImageOpacity={setQRPHImageOpacity}
                        isStaticQRPH={true}
                    />

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "0px 16px 24px",
                            width: "100%",
                        }}
                    >
                        <button 
                            className={`btn--${segments} btn-height`}
                            style={{
                                borderRadius: "4px",
                                width: "100%",
                                maxWidth: "400px",
                            }}
                            onClick={saveStaticQRPHBuxImage}
                            disabled={isDownloading}
                        >
                            <div hidden={!isDownloading}>
                                <Spin className={StaticQRPHStyles.spinner} />
                            </div>
                            <div 
                                hidden={isDownloading}
                            >
                                Download QR
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>     
    )
}

export default StaticQRPH;