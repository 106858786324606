import React, { useState, useEffect, useRef } from "react";
import {Input} from 'antd'
import enterpriseStyle from '../kybStyle'

const TextInput = props => {

    const kyb = enterpriseStyle()

    const {
        width,
        height,
        value,
        placeholder,
        name,
        changeValue,
        handlechangeSig,
        type,
        vipType,
        step,
        index,
        addBefore,
        maxLength,
        isError
    } = props


    const [error, setError] = useState(true)
    const [errorMessage, setErrorMessage] = useState(`${placeholder} is required`)
  
    const emailFormat = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

    const handleChange = (e) => {
        setError(true)

        if(step === 1){
            handlechangeSig(e, index, vipType)
        }
        
        else if(step === 4){
            handlechangeSig(e, index, name)
        }

        else {
           if(name == "email"){
            if(emailFormat.test(e)){
                setError(true)
            }
            else{
                setErrorMessage('Email format is invalid')
                setError(false)
            }
           }

           else if(name == "account_name") {
                let format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
                if(e.length == 30) {
                    setErrorMessage("Account Name max 30 characters reached.")
                    setError(false)
                }

                else if(format.test(e)){
                    setErrorMessage("Account name should not contain any special characters")
                    setError(false)
                }

                else {
                    setError(true)
                }
           }

           else if(name == "bank_branch") {
                if(e.length == 64) {
                    setErrorMessage("Bank Branch max 64 characters reached.")
                    setError(false)
                }

                else {
                    setError(true)
                }
           }
            changeValue(e)
        }

    };

    useEffect(() => {
        setError(!isError)
    }, [isError])


    return(
        <div>
        <Input 
            maxLength={maxLength} 
            addonBefore={
                addBefore ? addBefore : null
            } 
            type={type} 
            style={{
                height: height ? height : '32px',
                width: width, 
                borderColor: !error ? '#E24C4C' : ''
            }} 
            name={name} 
            value={value} 
            placeholder={placeholder} 
            onBlur={e => {
                    if(e.target.value.length === 0 && name != 'fax'){
                    setError(false)
                }
            }}
            onChange={e => {
                //step 1
                if(name == "postal"){
                    if(e.target.value.length <= 4){
                        handleChange(e.target.value)
                    }
                }
                else if(name == "office_phone"){
                    if(e.target.value.length <= 16){
                        handleChange(e.target.value)
                    }
                }
                else if(name == "gross_value" || name == "average_value" || name == "projected"){
                    if(e.target.value.length <= 13){
                        handleChange(e.target.value)
                    }
                }
                else if(step === 1){
                    handleChange(e)
                }
                //step 4
                else if(step === 4){
                    handleChange(e)
                }
                //step 6
                else if(name == "account_number"){
                    if(e.target.value.length <= 16){
                    handleChange(e.target.value)
                    }
                }

                else{
                    handleChange(e.target.value)
                }
            
            }}
        />
        <div hidden={error} align="left" className={kyb.miniText} style={{marginTop: 3, color: '#E24C4C'}}>{errorMessage}</div>

        </div>
    )
}
export default TextInput